import React from "react";

class RingWidget extends React.Component {
    render(){
        let r = 48.0;
        let strokeWidth = 12.0;
        let size = 2*r + strokeWidth
        let bottomDeg = 1;
        let bottomRad = bottomDeg/180 * Math.PI;
        let bottomSin = Math.sin(bottomRad);
        let bottomCos = Math.cos(bottomRad);
        let topDeg = 360 - this.props.percentPerUnit * 360;
        let topRad = topDeg/180 * Math.PI;
        let topSin = Math.sin(topRad);
        let topCos = Math.cos(topRad);
        let bigArc = topDeg > 180 ? 0 : 1  // ali vzamemo večji ali manjši arc
        let bottomArcPath = "M" + (r + strokeWidth / 2) + "," + (strokeWidth / 2) + " a" + r + "," + r + " 0 1,1 " + - bottomSin * r + ", " + (r - bottomCos * r)
        let topArcPath = "M" + (r + strokeWidth / 2) + "," + strokeWidth / 2 + " a" + r + "," + r + " 0 " + bigArc + ",1 " + (- topSin * r) + ", " + (r - topCos * r)
        let labelTopMargin = size / 2 - 30;
        return(
            <div style={{maxWidth: size, margin: "auto"}}>
                <div className="parent ">
                    <div className="svg-container">
                    <svg className="svg-content" viewBox="0 0 108 108" preserveAspectRatio="none">
                        <path className="bottom" d={bottomArcPath}
                              fill="none" stroke-width={strokeWidth} strokeLinecap="round" style={{stroke: this.props.darkMode ? "#485460" : ""}}/>
                        <path className="top animate" d={topArcPath}
                              fill="none" stroke-width={strokeWidth} strokeLinecap="round" />
                    </svg>
                    </div>
                    <p style={{width: "100%", marginTop: "-20px", color: this.props.darkMode ? "white" : ""}} className="ring-widget-title">{this.props.title}</p>
                    <p style={{width: "100%", marginTop: "10px", color: this.props.darkMode ? "white" : ""}} className="ring-widget-value">{this.props.value}</p>
                </div>
            </div>
        );
    }
}

export default RingWidget;