import React from "react";
import LineWidget from "./LineWidget";
import {secondsToTimestamp} from "./Helper";

class HeartRateZones extends React.Component {
    render() {
        let zones = this.getZones(this.props.activity);
        if(zones === undefined) {
            return(
                <div />
            );
        }
        let maxDuration = zones.reduce((partial, n) => n > partial ? n : partial, 0);
        let duration = maxDuration > 0 ? maxDuration : 1;
        return(
            <div className="container rounded frame">
                <div className="row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6">
                        <p className="title-small"> Heart Rate Zones</p>
                        <LineWidget title="Red zone" value={secondsToTimestamp(zones[4])} ppu={zones[4] / duration} color="#EF5777"/>
                        <LineWidget title="Anaerobic" value={secondsToTimestamp(zones[3])} ppu={zones[3] / duration} color="#FFA801"/>
                        <LineWidget title="Aerobic" value={secondsToTimestamp(zones[2])} ppu={zones[2] / duration} color="#FFD32A"/>
                        <LineWidget title="Moderate" value={secondsToTimestamp(zones[1])} ppu={zones[1] / duration} color="#0FBAF8"/>
                        <LineWidget title="Recovery" value={secondsToTimestamp(zones[0])} ppu={zones[0] / duration} color="#00D7D5"/>
                    </div>
                    <div className="col-lg-3" />
                </div>
            </div>
        );
    }

    getZones(activity){
        if (activity === undefined || activity.percentileCurves === undefined ){
            return;
        }
        let maxHeartRate = activity.userMaxHeartRate;
        let percentileCurve = activity.percentileCurves
            .find(x => x.percentileCurveType === "heartRate");

        if (percentileCurve === undefined ){
                return;
       }

        let percentileCurvePoints = percentileCurve.percentileCurvePoints;

        // Recovery
        let zone1Duration = percentileCurvePoints
            .filter(x => x.value <= maxHeartRate * 0.76)
            .map(x => x.duration)
            .reduce((partial, n) => n > partial ? n : partial, 0);

        // Moderate
        let zone2Duration = percentileCurvePoints
            .filter(x => x.value > maxHeartRate * 0.76 && x.value <= maxHeartRate * 0.83)
            .map(x => x.duration)
            .reduce((partial, n) => n > partial ? n : partial, 0);

        // Aerobic
        let zone3Duration = percentileCurvePoints
            .filter(x => x.value > maxHeartRate * 0.83 && x.value <= maxHeartRate * 0.90)
            .map(x => x.duration)
            .reduce((partial, n) => n > partial ? n : partial, 0);

        // Anaerobic
        let zone4Duration = percentileCurvePoints
            .filter(x => x.value > maxHeartRate * 0.90 && x.value <= maxHeartRate * 0.95)
            .map(x => x.duration)
            .reduce((partial, n) => n > partial ? n : partial, 0);

        // Red Zone
        let zone5Duration = percentileCurvePoints
            .filter(x => x.value > maxHeartRate * 0.95)
            .map(x => x.duration)
            .reduce((partial, n) => n > partial ? n : partial, 0);
        return [zone1Duration - zone2Duration,
            zone2Duration - zone3Duration,
            zone3Duration - zone4Duration,
            zone4Duration - zone5Duration,
            zone5Duration];
    }
}

export default HeartRateZones;