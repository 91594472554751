import React from "react";
import LineChart from "./LineChart";
import Map from "./Map";
import SegmentAnalysis from "./SegmentAnalysis";
import MapData from "./MapData";
import {getTimestamp, formatDate, createSamplesFromSamplesContainer} from './Helper.js';
import {Image} from "react-bootstrap";
import ActivityCard from "./ActivityCard";
import PowerAnalysis from "./PowerAnalysis";
import HeartRateZones from "./HeartRateZones";
import PowerZones from "./PowerZones";
import Overview from "./Overview";
import Loader from "../components/Loader";
import queryString from 'query-string';

class Activity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activity: undefined,
            activityId: undefined,
            unitSystem: "metric",
            segmentAnaylsisState: {
                segmentStart: undefined,
                segmentEnd: undefined,
                show: false
            },
            mapState: {
                activity: undefined,
                index: undefined
            }
        };
        this.onSegmentSelected = this.onSegmentSelected.bind(this);
        this.onHandleClose = this.onHandleClose.bind(this);
        this.onChartHover = this.onChartHover.bind(this);
        this.getActivity = this.getActivity.bind(this);

        //this.getActivity(this.props.activityId);
    }

    onChartHover(sampleIndex) {
        var mapData = new MapData(this.state.activity, sampleIndex);
        this.setState({
            mapState: [mapData]
        });
    }

    onSegmentSelected(firstindex, lastIndex){
        this.setState({
            segmentAnaylsisState: {
                segmentStart: firstindex,
                segmentEnd: lastIndex,
                show: true
            }
        });
    }

    onHandleClose() {
        this.setState({
            segmentAnaylsisState: {
                show: false
            }
        });
    }

    // Vedno ko se ta komponenta inicializira, ugotovimo id zadnjega Activity-a...
    componentDidMount() {
        let search = this.props.location.search;
        const values = queryString.parse(search)
        let activityid = values.activityId;
        this.getActivity(activityid);
        this.getActivity(activityid);   // Tukaj je zaradi neznanih razlogov potreben še ne zaporedni klic, saj se v nasportnem primeru ne
    }

    componentDidUpdate(prevProps, prevState) {
        //if prevProps.activityId !== this.props.activitId)
        //this.onChartHover(0);
        /*if (this.props.activityId !== prevProps.activityId){
            this.onChartHover(0);
        }*/
    }

    render() {
        if(this.state.activity === undefined)
            return (
                <Loader />
            );

        return (
             <div>
                <div className="container">
                    <p className="title-small" align="left"  style={{marginTop: 32, marginBottom: 0}}>{this.state.activity=== undefined ? 'You have no activities. Start cycling now!':this.getTitle(this.state.activity)}</p>
                    <p className="My-subtitle-light"  align="left">{formatDate( getTimestamp(this.state.activity), "DD.MM.YYYY HH:mm")}</p>
                    <Overview activity={this.state.activity} unitSystem={this.state.unitSystem}/>
                    <div style={{'marginTop':'50px'}} height="350px" className="container">
                        {this.state.mapState !== undefined && this.state.mapState.length > 0 &&  this.state.mapState[0].activity !== undefined &&  this.state.mapState[0].activity.samples !== undefined
                        &&  this.state.mapState[0].activity.samples.length > 0 &&  this.state.mapState[0].activity.samples[0].altitude !== undefined &&  this.state.mapState[0].activity.samples[0].altitude !== null
                            ? <Map data={this.state.mapState}/>
                            : <div></div>}
                        
                    </div>
                    <LineChart
                        activity={this.state.activity}
                        onChartHover={this.onChartHover}
                        onSegmentSelected={this.onSegmentSelected}
                    />
                    <PowerAnalysis style={{marginTop: "132px"}} activity={this.state.activity} year="2020" userId={this.state.userId} serverAccessToken={this.props.serverAccessToken}/>
                    <HeartRateZones activity={this.state.activity}/>
                    <PowerZones activity={this.state.activity}/>

                </div>
                {/*<div style={{'marginTop':'50px'}} height="550px" className="container">
                    <Map data={this.state.mapState}/>
                </div>
                <div className="container">
                    <LineChart
                        activity={this.state.activity}
                        height="3"
                        width="10"
                        onChartHover={this.onChartHover}
                        onSegmentSelected={this.onSegmentSelected}
                    />
                </div>
                <p className="My-panel-text">Hint: drag&drop to select a section of the chart and see some magic going on...</p>
                <SegmentAnalysis serverAccessToken={this.props.serverAccessToken}
                                 show={this.state.segmentAnaylsisState.show}
                                 activityId={activityid}
                                 segmentStart={this.state.segmentAnaylsisState.segmentStart}
                                 segmentEnd={this.state.segmentAnaylsisState.segmentEnd}
                                 onHandleClose={this.onHandleClose}
                />*/}
            </div>
        );
    }

    getTableItem(title, value, icon)
    {
        return(
            <React.Fragment>
                <td>
                    <Image src={icon} height="32px"/>
                </td>
                <td className="My-panel-table-item" align="left">
                    <p className="My-panel-table-item-key">{title}</p>
                    <p className="My-panel-table-item-value">
                        {value}
                    </p>
                </td>
            </React.Fragment>
        );
    }

    getTitle(activity) {
        if (activity !== undefined) {
            return activity.name;
        } else {
            return "...";
        }
    }

    getActivity(activityId)
    {
        if(activityId !== undefined)
        {
            let urlOfFunc = '/Activity/getSharedActivity';

            fetch("https://service.saddleaddict.com" + urlOfFunc + '?activityId=' + activityId,{
                headers: new Headers({'Authorization': "Bearer " + this.props.serverAccessToken})
            })
                .then(res => res.json())
                .then(
                    ((result) => {
                            if (result.status === undefined) // Ce je 200, ne pride nazaj status ampak samo direkt Json, kar pomeni da ce je status undefined, imamo rezultat
                            {
                                var username = result.value0;
                                var userid = result.value1;
                                var unitSystem = result.value2;
                                var activity = result.value3;
                                if(activity === undefined)
                                {
                                    username = "Your friend"
                                    activity = result;
                                }                                    
                                activity.samples = createSamplesFromSamplesContainer(activity.samplesContainer);
                                this.setState({
                                    activity: activity,
                                    activityId: activity.id,
                                    unitSystem: unitSystem,
                                    sharedUsername: username,
                                    userId: userid,
                                    mapState: [
                                        {
                                            activity: activity
                                        }
                                    ]
                                });
                                this.props.onNameChange(username);  // 5.4.2022: ta klic mora bit nujno za set state, sicer se zacikla render
                            }
                        },
                            // Note: it's important to handle errors here
                            // instead of a catch() block so that we don't swallow
                            // exceptions from actual bugs in components.
                            (error) => {
                                var username = error.value0;
                                var activity = error.value3;
                                var userid = error.value1;
                                var unitSystem = error.value2;
                                if(activity === undefined)
                                {
                                    username = "Your friend"
                                    activity = error;
                                }        
                                activity.samples = createSamplesFromSamplesContainer(activity.samplesContainer);
                                this.setState({
                                    activity: activity,
                                    activityId: activity.id,
                                    unitSystem: unitSystem,
                                    sharedUsername: username,
                                    userId: userid,
                                    mapState: [
                                        {
                                            activity: activity
                                        }
                                    ]
                                });
                                this.props.onNameChange(username);      // 5.4.2022: ta klic mora bit nujno za set state, sicer se zacikla render
                            }
                    ))
        }
    }
}

export default Activity;