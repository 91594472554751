import React, { PureComponent } from 'react'
import { CanvasOverlay } from 'react-map-gl'

class PolylineOverlay extends PureComponent {
  _redraw ({ width, height, ctx, isDragging, project, unproject }) {
    const { points, hoverPairs, color = 'rgba(239, 87, 119, 0.7)', lineWidth = 5, renderWhileDragging = true } = this.props
    var hoverColors = ['rgba(54, 162, 235, 1)', 'rgba(54, 162, 235, 1)'];
    ctx.clearRect(0, 0, width, height)
    ctx.globalCompositeOperation = 'lighter'

    if ((renderWhileDragging || !isDragging) && points) {
      ctx.lineWidth = lineWidth
      ctx.strokeStyle = color
      ctx.fillStyle = color
      ctx.beginPath()
      points.forEach(point => {
        if(point[0] != null && point[1]!= null)
        {
          const pixel = project([point[0], point[1]])
          ctx.lineTo(pixel[0], pixel[1])
        }
      })
      ctx.stroke()
      
      if (hoverPairs)
      {
        /*hoverPairs.forEach(hoverPair => {
          ctx.beginPath();
            const pixel = project([hoverPair[0], hoverPair[1]])
            ctx.arc(pixel[0], pixel[1], 4, 0, 2 * Math.PI);
            ctx.stroke();
            ctx.fill();
        });*/
        for (var i = 0; i < hoverPairs.length; i++)
        {
          ctx.strokeStyle = hoverColors[i];
          ctx.fillStyle = hoverColors[i];
          ctx.beginPath();
            const pixel = project([hoverPairs[i][0], hoverPairs[i][1]])
            ctx.arc(pixel[0], pixel[1], 4, 0, 2 * Math.PI);
            ctx.stroke();
            ctx.fill();
        }
    }
        // Narisemo krozec na hoverPointu
        /*if (hoverPoint){
            ctx.beginPath();
            const pixel = project([hoverPoint[0], hoverPoint[1]])
            ctx.arc(pixel[0], pixel[1], 4, 0, 2 * Math.PI);
            ctx.stroke();
            ctx.fill();
        }*/
    }
  }

  render () {
    return <CanvasOverlay redraw={this._redraw.bind(this)} />
  }
}

export default PolylineOverlay;