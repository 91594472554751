import React from "react";
import smoothscroll from "smoothscroll-polyfill";

class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            titleSize: "20px",
            bodySize: "20px"
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    resize() {
        if (window.innerWidth > 1400){
            this.setState({
                titleSize: "15px",
                bodySize: "16px"
            })
        }
        else {
            this.setState({
                titleSize: "15px",
                bodySize: "16px"
            })
        }
    }


    render() {
        return(
            <div className="card" style={{backgroundColor: this.props.color}}>
                <table className="align-middle" width="100%" height="100%">
                    <td width="40px">
                        {this.props.icon}
                    </td>
                    <td style={{maxWidth: "340px"}}>
                        <p style={{color: this.props.titleColor, fontSize: this.state.titleSize}} className="p-card-title">{this.props.title}</p>
                        <p style={{color: this.props.detailsColor, fontSize: this.state.bodySize}} className="p-card-body">{this.props.details}</p>
                    </td>
                </table>
            </div>
        );
    }
}

export default Card;