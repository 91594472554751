import React from "react";
import programJson from "../sections/programs.json";
import {forEach} from "react-bootstrap/ElementChildren";

/*export class Interval{
    constructor(duration, value, color) {
        this.duration = duration;
        this.value = value;
        this.color = color;
    }
}*/

class PowerZone{
    constructor(lowerBound, upperBound, color) {
        this.lowerBound = lowerBound;
        this.upperBound = upperBound;
        this.color = color;
    }
}

let powerZones = [
    new PowerZone(0.0, 0.55, "#34E7E3"),
    new PowerZone(0.55, 0.75, "#00D7D5"),
    new PowerZone(0.75, 0.90, "#0FBAF8"),
    new PowerZone(0.90, 1.05, "#0CA9E2"),
    new PowerZone(1.05, 1.20, "#FFD32A"),
    new PowerZone(1.20, 1.50, "#FFA801"),
    new PowerZone(1.50, 10, "#EF5777"),
];

let programs = JSON.parse(JSON.stringify(programJson));
programs.forEach(program => {});

class IntervalTraining extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render(){
        let margin = 1.0
        let totalWidth = this.props.width;
        let totalHeight = 200;
        let sum = this.props.program.intervals.map(x => x.duration).reduce((prev, current) => {return(prev + current)});
        let intervalCount = this.props.program.intervals.length;
        let widthFactor = (totalWidth - 2 * margin * intervalCount) / sum;
        return(
            <div>
                <div style={{display: "flex", alignItems: "end", height: totalHeight, backgroundColor: "rgb(240, 240, 240)", clipPath: "inset(0 round 20px)"}}>
                    {this.props.program.intervals.map((interval) => (
                        <div>
                            <div style={{width: interval.duration * widthFactor,
                                height: interval.setpoint / 2 * totalHeight,
                                marginLeft: "1px",
                                marginRight: "1px",
                                borderRadius: "4px",
                                backgroundColor: powerZones.find(x =>(x.lowerBound <= interval.setpoint && x.upperBound > interval.setpoint))?.color}}/>
                        </div>
                        ))}
                </div>
            </div>
        );
    }
}

export default IntervalTraining;