import React from "react";
import smoothscroll from "smoothscroll-polyfill";

class OverviewCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            innerWidth: null
        }
    }

    resize() {
        this.setState({innerWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    determineFontSize(){
        if (this.state.innerWidth < 450){
            return "14px"
        }
        else if (this.state.innerWidth < 1000){
            return "16px"
        }
        else{
            return "20px"
        }
    }

    render() {
        return(
            <div className="overview-card">
                <div className="overview-card-segment-1">
                    {this.props.icon}
                </div>
                <div className="overview-card-segment-2">
                    <p className="overview-card-title" style={{fontSize: this.determineFontSize()}}>{this.props.title}</p>
                </div>
            </div>
        );
    }
}

export default OverviewCard;