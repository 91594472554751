import React from "react";
import './PowerAnalysis.css';
import RingWidget from "./RingWidget";
import Chart from "chart.js";
import {secondsToTimestamp} from "./Helper";
import Moment from 'moment'

class PowerAnalysis extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refPowerCurve: undefined,
            activityId: undefined
        };
    }

    componentDidMount() {
        this.getPowerCurve(this.props.userId, this.props.year);
        if (this.props.activity.powerCurves !== undefined && this.props.activity.powerCurves.length > 0) {
            this.populateChart(this.props.activity.powerCurves[0].powerCurvePoints.map(x => secondsToTimestamp(x.duration)),
                this.props.activity.powerCurves[0].powerCurvePoints.map(x => x.value));
        }
    }

    render() {
        if (this.props.activity.powerCurves === undefined || (this.props.activity.powerCurves !== undefined && this.props.activity.powerCurves.length === 0)){
            return(
                <div/>
            );
        }
        var ppu1 = 0.0;
        if (this.props.activity.powerCurves !== undefined && this.props.activity.powerCurves.length > 0 && this.props.activity.powerCurves[0].fiveSec !== undefined
            && this.props.activity.powerCurves[0].fiveSec !== null
            && this.state.refPowerCurve !== undefined
            && this.state.refPowerCurve.fiveSec !== null
            && this.state.refPowerCurve.fiveSec !== 0){
            ppu1 = this.props.activity.powerCurves[0].fiveSec / this.state.refPowerCurve.fiveSec;
        }
        var ppu2= 0.0;
        if (this.props.activity.powerCurves !== undefined && this.props.activity.powerCurves.length > 0 && this.props.activity.powerCurves[0].oneMin !== undefined
            && this.props.activity.powerCurves[0].oneMin !== null
            && this.state.refPowerCurve !== undefined
            && this.state.refPowerCurve.oneMin !== null
            && this.state.refPowerCurve.oneMin !== 0){
            ppu2 = this.props.activity.powerCurves[0].oneMin / this.state.refPowerCurve.oneMin;
        }
        var ppu3= 0.0;
        if (this.props.activity.powerCurves !== undefined && this.props.activity.powerCurves.length > 0 && this.props.activity.powerCurves[0].fiveMin !== undefined
            && this.props.activity.powerCurves[0].fiveMin !== null
            && this.state.refPowerCurve !== undefined
            && this.state.refPowerCurve.fiveMin !== null
            && this.state.refPowerCurve.fiveMin !== 0){
            ppu3 = this.props.activity.powerCurves[0].fiveMin / this.state.refPowerCurve.fiveMin;
        }
        var ppu4= 0.0;
        if (this.props.activity.powerCurves !== undefined && this.props.activity.powerCurves.length > 0 && this.props.activity.powerCurves[0].twentyMin !== undefined
            && this.props.activity.powerCurves[0].twentyMin !== null
            && this.state.refPowerCurve !== undefined
            && this.state.refPowerCurve.twentyMin !== null
            && this.state.refPowerCurve.twentyMin !== 0){
            ppu4 = this.props.activity.powerCurves[0].twentyMin / this.state.refPowerCurve.twentyMin;
        }

        return(
            <div style={{marginTop: "32px"}}>
                <div className="row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6">
                        <p className="title-small">Power Bests</p>
                        <canvas id="powerChart" width="400" height="200" style={{marginTop: 32}}/>
                        {this.props.activity.powerCurves !== undefined && this.props.activity.powerCurves.length > 0 ? (
                        <table width="100%" style={{marginTop: 24}}>
                        <tr>
                            <td>
                                <RingWidget percentPerUnit={ppu1} title="5s" value={this.props.activity.powerCurves[0].fiveSec}/>
                            </td>
                            <td>
                                <RingWidget percentPerUnit={ppu2} title="1m" value={this.props.activity.powerCurves[0].oneMin}/>
                            </td>
                            <td>
                                <RingWidget percentPerUnit={ppu3} title="5m" value={this.props.activity.powerCurves[0].fiveMin}/>
                            </td>
                            <td>
                                <RingWidget percentPerUnit={ppu4} title="20m" value={this.props.activity.powerCurves[0].twentyMin}/>
                            </td>
                        </tr>
                    </table>
                    ) : (
                        <div></div>
                    )}
                        
                    </div>
                </div>
            </div>
        );
    }

    populateChart(xDataSet, yDataSet){
        var ctx = document.getElementById('powerChart').getContext('2d');
        new Chart(ctx,{
            type: "line",
            data: {
                labels: xDataSet,
                datasets: [
                    {
                        label: "power",
                        yAxisID: "power",
                        data: yDataSet,
                        pointRadius: 1,
                        pointHitRadius: 5,
                        backgroundColor: "rgba(15, 186, 248, 0.4)",
                        borderColor: "rgba(15, 186, 248, 0.8)",
                        borderWidth: 0.5
                    }
                ]
            },
            plugins: [
            ],
            options: {
                events: ["mouseup", "mousedown", "click", "mousemove"],
                hover: {
                    intersect: false,
                    onHover: function (evt, item) {
                        if (item.length) {
                            //self.chartHover(usedSampleIndexes[item[0]._index]);
                            //console.log("item index: ", item[0]._index, evt.type);
                        }
                        //var a = 1;
                    }
                },
                maintainAspectRatio: true,
                spanGaps: true,
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [
                        {
                            id: "power",
                            ticks: {
                                fontColor: "black",
                                fontFamily: "Montserrat",
                                beginAtZero: true
                            },
                            gridLines: {
                                display: false
                            },
                        }
                    ],
                    xAxes: [
                        {
                            display: true,
                            gridLines: {
                                display: false
                            }
                        }
                    ]
                },
                plugins: {
                    zoom: {
                        pan: {
                            enabled: false,
                            mode: "xy"
                        },
                        zoom: {
                            drag: true,
                            enabled: false,
                            speed: 0.1,
                            mode: "x",
                            rangeMin: {
                                // Format of min zoom range depends on scale type
                                x: 100,
                                y: 100
                            }
                        }
                    }
                }
            }
        });
    }

    getPowerCurve(userId, year)
    {
        if (userId === undefined){
            return;
        }

        var startdate = new Date((year - 1) + '-12-31T23:00:00.000');
        var endDate =   new Date(year + '-12-31T22:23:23.000');
        startdate = Moment(startdate.toString()).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        endDate = Moment(endDate.toString()).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

        var startdate = startdate.slice(0, 26) + startdate.slice(27);//ios primer 2022-01-01T00:00:00.000%2B0100
        var startdate = startdate.replace('+','%2B');

        var endDate = endDate.slice(0, 26) + endDate.slice(27);//ios primer 2022-01-01T00:00:00.000%2B0100
        var endDate = endDate.replace('+','%2B');

        let urlOfFunc = '/Analysis/getSharedPowerCurveForInterval';
        let url = "https://service.saddleaddict.com" + urlOfFunc + '?userId=' + userId + '&startDate=' + startdate+'&endDate=' + endDate;
        fetch(url)
            .then(res => res.json())
            .then(
                ((result) => {
                        if (result.status === undefined) // Ce je 200, ne pride nazaj status ampak samo direkt Json, kar pomeni da ce je status undefined, imamo rezultat
                        {
                            this.setState({
                                refPowerCurve: result,
                            });
                        }
                    },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({
                                refPowerCurve: error,
                            });
                        }
                ))
    }
}

export default PowerAnalysis;