import React from "react";
import ActivityCard from "./ActivityCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faGaugeSimpleHigh,
    faRulerHorizontal,
    faRulerVertical,
    faBolt,
    faClock,
    faHeart,
    faUpDown,
    faRotate,
    faFaceGrinBeamSweat, faTemperatureFull
} from '@fortawesome/free-solid-svg-icons'

class Overview extends React.Component {
    render() {
        return(
            <div className="container rounded frame">
                <div className="row">
                    <div className="col-lg-6">
                        <table style={{justifyContent: "center", alignItems: "center"}} width="100%">
                            <tr>
                                <td width="50%">
                                    <ActivityCard title="DISTANCE" details={this.getDistance(this.props.activity, this.props.unitSystem)}
                                                  icon={<FontAwesomeIcon className="fa-2xl" icon={faRulerHorizontal} color="#47535F" />}/>
                                </td>
                                <td width="50%">
                                    <ActivityCard title="AVG SPEED" details={this.getAvgSpeed(this.props.activity, this.props.unitSystem)}
                                                  icon={<FontAwesomeIcon className="fa-2xl" icon={faGaugeSimpleHigh} color="#0FBAF8" />}/>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="col-lg-6">
                        <table style={{justifyContent: "center", alignItems: "center"}} width="100%">
                            <tr>
                                <td width="50%">
                                    {this.populate3rdField(this.props.activity, this.props.unitSystem)}
                                </td>
                                <td width="50%">
                                    <ActivityCard title="AVG POWER" details={this.getAvgPower(this.props.activity)}
                                                  icon={<FontAwesomeIcon className="fa-2xl" icon={faBolt} color="#00D7D5" />}/>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <table style={{justifyContent: "center", alignItems: "center"}} width="100%">
                            <tr>
                                <td width="50%">
                                    <ActivityCard title="DURATION" details={this.getDuration(this.props.activity)}
                                                  icon={<FontAwesomeIcon className="fa-2xl" icon={faClock} color="#1E272E" />}/>
                                </td>
                                <td width="50%">
                                    <ActivityCard title="AVG PULSE" details={this.getAvgHeartrate(this.props.activity)}
                                                  icon={<FontAwesomeIcon className="fa-2xl" icon={faHeart} color="#EF5777" />}/>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="col-lg-6">
                        <table style={{justifyContent: "center", alignItems: "center"}} width="100%">
                            <tr>
                                <td width="50%">
                                    {this.populate7thField(this.props.activity, this.props.unitSystem)}
                                </td>
                                <td width="50%">
                                    <ActivityCard title="AVG CADENCE" details={this.getAvgCadence(this.props.activity)}
                                                  icon={<FontAwesomeIcon className="fa-2xl" icon={faRotate} color="#FFA801" />}/>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    getDescription(activity) {
        if (activity !== undefined) {
            return activity.description;
        } else {
            return "...";
        }
    }

    getDistance(activity, unitSystem) {
        if (activity !== undefined) {
            if (unitSystem === "metric") {
                return Math.round(activity.totalDistance) / 1000 + " km";
            }
            else{
                return Math.round(activity.totalDistance  / 1.609344) / 1000 + " mi";
            }
        } else {
            return "...";
        }
    }

    getAvgSpeed(activity, unitSystem) {
        if (activity !== undefined) {
            if (unitSystem === "metric") {
                return Math.round(activity.avgSpeed * 3.6 * 10) / 10 + " km/h";
            }
            else{
                return Math.round(activity.avgSpeed  * 2.23693629 * 10) / 10 + " mph";
            }
        } else {
            return "...";
        }
    }

    // to polje zavzame različne vrednosti odvisno od tipa activitya
    populate3rdField(activity, unitSystem) {
        if (activity.activityType === "programBasedTraining" || activity.activityType === "workout") {
            return (
                <ActivityCard title="WORKLOAD"
                              details={this.getWorkload(activity, unitSystem)}
                              icon={<FontAwesomeIcon className="fa-2xl" icon={faFaceGrinBeamSweat} color="#808E9B"/>}/>
            );
        } else {
            return (
                <ActivityCard title="TOTAL ASCENT"
                              details={this.getTotalAscent(this.props.activity, this.props.unitSystem)}
                              icon={<FontAwesomeIcon className="fa-2xl" icon={faRulerVertical} color="#808E9B"/>}/>
            );
        }
    }

    // to polje zavzame različne vrednosti odvisno od tipa activitya
    populate7thField(activity, unitSystem) {
        if (activity.activityType === "programBasedTraining" || activity.activityType === "workout") {
            return (
                <ActivityCard title="INTENSITY"
                              details={this.getIntensity(activity)}
                              icon={<FontAwesomeIcon className="fa-2xl" icon={faTemperatureFull} color="#0FBAF8"/>}/>
            );
        } else {
            return (
                <ActivityCard title="ELEV. GAIN"
                              details={this.getElevationGain(activity, unitSystem)}
                              icon={<FontAwesomeIcon className="fa-2xl" icon={faUpDown} color="#0FBAF8"/>}/>
            );
        }
    }

    getElevationGain(activity, unitSystem) {
        if (activity !== undefined) {
            if (unitSystem === "metric"){
                return Math.round(activity.elevationGain) + " m";
            }
            else{
                return Math.round(activity.elevationGain * 3.2808399) + " ft";
            }
        } else {
            return "...";
        }
    }

    getIntensity(activity) {
        if (activity !== undefined && activity.program !== undefined) {
            return Math.round(activity.program.intensityFactor * 100) + " %";
        } else {
            return "...";
        }
    }

    getWorkload(activity) {
        if (activity !== undefined && activity.program !== undefined) {
            return Math.round(activity.program.trainingStressScore);
        } else {
            return "...";
        }
    }

    getAvgPower(activity) {
        if (activity !== undefined) {
            return Math.round(activity.avgPower) + " W";
        } else {
            return "...";
        }
    }

    getTotalAscent(activity, unitSystem) {
        if (activity !== undefined) {
            if (unitSystem === "metric") {
                return Math.round(activity.totalAscent) + " m";
            }
            else{
                return Math.round(activity.totalAscent * 3.2808399) + " ft";
            }
        } else {
            return "...";
        }
    }

    getAvgHeartrate(activity) {
        if (activity !== undefined) {
            return Math.round(activity.avgHeartRate) + " bpm";
        } else {
            return "...";
        }
    }

    getAvgCadence(activity) {
        if (activity !== undefined) {
            return Math.round(activity.avgCadence) + " rpm";
        } else {
            return "...";
        }
    }

    getDuration(activity) {
        if (activity !== undefined) {
            var date = new Date(null);
            date.setSeconds(activity.totalDuration!==undefined?activity.totalDuration:0); // specify value for SECONDS here
            return date.toISOString().substr(11, 8);
        } else {
            return "...";
        }
    }
}

export default Overview;