import React from "react";
import {createSamplesFromSamplesContainer} from "./activity/Helper";
import queryString from "query-string";


class AccountValidation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validationKey: undefined,
            validationSuccessful: undefined
        };
        //this.onSegmentSelected = this.onSegmentSelected.bind(this);
    }

    onValidationSuccess(){
        this.setState({
            validationSuccessful: true
        })
    }

    onValidationError(){
        this.setState({
            validationSuccessful: false
        })
    }

    componentDidMount() {
        let search = this.props.location.search;
        const values = queryString.parse(search)
        let email = values.email
        let validationKey = values.validationKey
        this.validateAccount(email, validationKey)
    }

    render(){
        return(
            <div>
                <p className="title-small">{this.showMessage()}</p>
            </div>
        );
    }

    showMessage(){
        if (this.state.validationSuccessful !== undefined && this.state.validationSuccessful){
            return "Account Validation successful! Thanks for joining our community!"
        }
        else if (this.state.validationSuccessful !== undefined && !this.state.validationSuccessful){
            return "An error occurred during the Account validation process. Please try again later or contact support."
        }
    }

    validateAccount(email, validationKey)
    {
        if(validationKey !== undefined)
        {
            let urlOfFunc = '/validateAccount';

            fetch("http://Bostjans-MacBook-Pro.local:3000" + urlOfFunc + "?email=" + email + '&validationKey=' + validationKey, {method: 'POST'})
                .then((response) => {
                    if (response.status === 200){
                        this.setState({
                            validationSuccessful: true
                        })
                    }
                    else{
                        this.setState({
                            validationSuccessful: false
                        })
                    }
                })
        }
    }
}

export default AccountValidation;