import React from "react";

import Button from "react-bootstrap/Button";
//import banner265 from "../videos/sa-site-video-2-265.mp4";
import bannerMov from "../videos/sa-video-2.mov";
import banner264 from "../videos/sa-site-video-2-264.mp4";
import image from "../images/final/rider-1.jpg";
import {Image, Spinner} from "react-bootstrap";

class HeaderSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            innerWidth: null
        }
    }

    resize() {
        this.setState({innerWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    render() {
        return(
            <div className="overflow-hidden" style={{height: "550px", position: "relative"}}>
                <video className="background-video" fluid autoPlay loop muted solid playsInline width="100%" max>
                    {/*<source src={banner265}  type="video/mp4"/>*/}
                    <source src={bannerMov} type="video/quicktime"/>
                    <source src={banner264} type="video/mp4"/>
                    <div className="bck-image" style={{height: "550px"}}/>
                </video>
                <div className="background-video" style={{backgroundColor: "black", zIndex: -3, display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Spinner animation="border" role="status" variant="light">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
                <div style={{height: "250px", color: "white"}}/>
                <div style={{height: "300px", color: "white", width: "100%"}} className="background-video-gradient">
                    <div className="container" style={{width: "100%"}}>
                        <p className="title light-color" style={{fontSize: this.getTitleFontSize()}}>BIKE ACTIVITY TRACKING & INDOOR TRAINING APP</p>
                        <div style={{textAlign: "left"}}>
                            <Button href="https://apps.apple.com/si/app/saddle-addict/id1589658144" className="link-button" style={{marginLeft: this.getButtonLeftMargin(), marginBottom: "30px"}}>TRY FOR FREE</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getTitleFontSize(){
        if (this.state.innerWidth > 1000){
            return 45
        }
        else if (this.state.innerWidth > 450){
            return 38
        }
        else{
            return 32
        }
    }

    getButtonLeftMargin(){
        if (this.state.innerWidth > 1000){
            return 80
        }
        else if (this.state.innerWidth > 450){
            return 80
        }
        else{
            return 40
        }
    }
}

export default HeaderSection;