import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SegmentAnalysisHistoryChart from './SegmentAnalysisHistoryChart';
import SegmentAnalysisLineChart from "./SegmentAnalysisLineChart";
import Map from "./Map";
import MapData from "./MapData";




class SegmentAnalysis extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSegment: undefined,
            segments: [],
            selectedSegment: undefined,
            mapData: []
        };
        this.onChartHover = this.onChartHover.bind(this);
        this.onActivityClicked = this.onActivityClicked.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState){
        if (nextProps.activityId !== this.props.activityId
            || nextProps.segmentStart !== this.props.segmentStart
            || nextProps.segmentEnd !== this.props.segmentEnd
            || nextState !== this.state){
            return true;
        }
        else{
            return false;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.show && this.props !== prevProps){
            this.compareWithPreviousActivities(
                this.props.activityId,
                this.props.segmentStart,
                this.props.segmentEnd
            );
        }

        /*this.compareWithPreviousActivities(
          this.props.activityId,
          this.props.segmentStart,
          this.props.segmentEnd);*/
    }

    onActivityClicked(rowId){
        var selectedSegment = this.state.segments.filter(function(segment){
            return segment.id === rowId;
        })[0];
        this.setState({
            selectedSegment: selectedSegment
        });
    }

    render() {
        return (
            <Modal dialogClassName="My-modal" show={this.props.show} onHide={this.props.onHandleClose} centered>
                <Modal.Header className="My-Chart-Modal" closeButton>
                    <Modal.Title align="center">Segment analysis</Modal.Title>
                </Modal.Header>
                <Modal.Body className="My-Chart-Modal">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <table className="table">
                                    <tbody>
                                    {this.getTableItem(this.getDistance(this.state.currentSegment), "fa-road")}
                                    {this.getTableItem(this.getTotalAscent(this.state.currentSegment), "fa-chevron-up")}
                                    </tbody>
                                </table>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th/>
                                        <th>Current</th>
                                        <th>Best</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.getTableRow(this.getDuration(this.state.currentSegment), this.getDuration(this.state.selectedSegment), "fa-stopwatch")}
                                    {this.getTableRow(this.getAvgSpeed(this.state.currentSegment), this.getAvgSpeed(this.state.selectedSegment), "fa-tachometer-alt")}
                                    {this.getTableRow(this.getAvgHeartrate(this.state.currentSegment), this.getAvgHeartrate(this.state.selectedSegment), "fa-heart")}
                                    {this.getTableRow(this.getAvgPower(this.state.currentSegment), this.getAvgPower(this.state.selectedSegment), "fa-bolt")}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-6">
                                <table className="table">
                                    <tbody>
                                    {this.getTableItem(this.getAvgClimb(this.state.currentSegment), "fa-mountain")}
                                    {this.getTableItem(this.getMaxClimb(this.state.currentSegment), "fa-mountain")}
                                    </tbody>
                                </table>
                                <SegmentAnalysisHistoryChart segments={this.state.segments} />
                            </div>
                        </div>
                    </div>
                    <SegmentAnalysisLineChart serverAccessToken={this.props.serverAccessToken}
                                              activity={this.state.currentSegment}
                                              height="3"
                                              width="10"
                                              onChartHover={this.onChartHover} />
                    <Map data={this.state.mapData}/>
                </Modal.Body>
                <Modal.Footer className="My-Chart-Modal">
                    <Button variant="secondary" onMouseDown={this.props.onHandleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onMouseDown={this.props.onHandleClose}>
                        Compare with previous rides
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    onChartHover(currentSegmentIndex) {
        var mapData = [new MapData(this.state.currentSegment, currentSegmentIndex)];
        if (this.state.selectedSegment)
        {
            var accumulatedDuration = this.state.currentSegment.samples[currentSegmentIndex].accumulatedDuration;
            var previousDiff = undefined;
            for (var i = 0; i < this.state.selectedSegment.samples.length; i++)
            {
                var diff = Math.abs(this.state.selectedSegment.samples[i].accumulatedDuration - accumulatedDuration);
                if ((previousDiff != null) && (diff > previousDiff))
                {
                    mapData.push(new MapData(this.state.selectedSegment, i-1));
                    break;
                }
                previousDiff = diff;
            }
        }
        this.setState({
            mapData: mapData
        });
    }

    getTableItem(value, icon)
    {
        var iconClass = "fas " + icon + " My-panel-table-item-icon";
        return(
            <tr>
                <td className="My-panel-table-item">
                    <i className={iconClass}></i>
                </td>
                <td className="My-panel-table-item">
                    <p className="My-panel-table-item-value">
                        {value}
                    </p>
                </td>
            </tr>
        );
    }

    getTableRow(currentValue, bestValue, icon)
    {
        var iconClass = "fas " + icon + " My-panel-table-item-icon";
        return(
            <tr>
                <td className="My-panel-table-item">
                    <i className={iconClass}></i>
                </td>
                <td className="My-panel-table-item">
                    <p className="My-panel-table-item-value">
                        {currentValue}
                    </p>
                </td>
                <td className="My-panel-table-item">
                    <p className="My-panel-table-item-value">
                        {bestValue}
                    </p>
                </td>
            </tr>
        );
    }

    getTitle(activity) {
        if (activity !== undefined) {
            return activity.name;
        } else {
            return "...";
        }
    }

    getDistance(activity) {
        if (activity !== undefined) {
            return Math.round(activity.totalDistance) / 1000 + " km";
        } else {
            return "...";
        }
    }

    getAvgSpeed(activity) {
        if (activity !== undefined) {
            return Math.round(activity.avgSpeed) + " km/h";
        } else {
            return "...";
        }
    }

    getElevationGain(activity) {
        if (activity !== undefined) {
            return Math.round(activity.elevationGain) + " m";
        } else {
            return "...";
        }
    }

    getAvgPower(activity) {
        if (activity !== undefined) {
            return Math.round(activity.avgPower) + " W";
        } else {
            return "...";
        }
    }

    getTotalAscent(activity) {
        if (activity !== undefined) {
            return Math.round(activity.totalAscent) + " m";
        } else {
            return "...";
        }
    }

    getAvgClimb(activity) {
        if (activity !== undefined) {
            return Math.round(activity.avgClimb) + " % avg";
        } else {
            return "...";
        }
    }

    getMaxClimb(activity) {
        if (activity !== undefined) {
            return Math.round(activity.maxClimb) + " % max";
        } else {
            return "...";
        }
    }

    getAvgHeartrate(activity) {
        if (activity !== undefined) {
            return Math.round(activity.avgHeartRate) + " bpm";
        } else {
            return "...";
        }
    }

    getAvgCadence(activity) {
        if (activity !== undefined) {
            return Math.round(activity.avgCadence) + " rpm";
        } else {
            return "...";
        }
    }

    getDuration(activity) {
        if (activity !== undefined) {
            var date = new Date(null);
            date.setSeconds(activity.totalDuration); // specify value for SECONDS here
            return date.toISOString().substr(11, 8);
        } else {
            return "...";
        }
    }


    compareWithPreviousActivities(activityId, startSequence, endSequence) {
        /*if (activityId === this.state.lastActivityId){
                return;
            }*/
        fetch(  "https://service.saddleaddict.com/Activity/compareWithPreviousRides/?rideId=" +
            activityId +  '&startSequence=' +
            startSequence + '&endSequence=' +
            endSequence,
            {
                headers: new Headers({'Authorization': "Bearer " + this.props.serverAccessToken}
                )
            }
        )
            .then(res => res.json())
            .then(
                result => {
                    if (result.status === undefined) {
                        // Ce je 200, ne pride nazaj status ampak samo direkt Json, kar pomeni da ce je status undefined, imamo rezultat
                        // rezultati (Segmenti) so razvrsceni glede na trajanje. Vsaj en vzorec (ta ki ga naliziramo) se vedno vrne.
                        var currentSegment = result.filter(function(segment){
                            return segment.activityId === activityId;
                        })[0];
                        var selectedSegment = undefined;
                        if (result[0].id !== currentSegment.id){
                            selectedSegment = result[0];
                        }
                        else if (result.length > 1){
                            selectedSegment = result[1];
                        }
                        /*var minDuration = Math.min.apply(null, result.map(function(segment){
                          return segment.duration;
                        }));
                        var bestSegment = result.filter(function(item){
                          return item.duration === minDuration;
                        });*/
                        this.setState({
                            currentSegment: currentSegment,
                            segments: result,
                            selectedSegment: selectedSegment,
                            mapData: [new MapData(result[0], undefined)]
                        });
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                error => {}
            );
    }
}

export default SegmentAnalysis;