import React from "react";

class ActivityCard extends React.Component {
    render() {
        return(
            <div className="card" style={{backgroundColor: this.props.color}}>
                <table width="100%">
                    <td width="48px">
                        {this.props.icon}
                    </td>
                    <td className="td-activity-card">
                        <p style={{marginLeft: "16px"}} className="p-card-title">{this.props.title}</p>
                        <p style={{marginLeft: "16px"}} className="p-card-value">{this.props.details}</p>
                    </td>
                </table>
            </div>
        );
    }
}

export default ActivityCard;