import React from "react";
// import selectPlugin from "chartjs-plugin-select";  // plugin za drag&select
import SegmentAnalysis from "./SegmentAnalysis";
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'

var self = undefined;
let SAMPLES_RESOLUTION = 1280;

class SegmentAnalysisLineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: Math.random(),
      lastChart: undefined,
      lastActivityId: undefined,
      show: false,
      segmentStart: undefined,
      segmentEnd: undefined
    };
    this.handleClose = this.handleClose.bind(this);
  }

  render() {
    self = this;
    return (
      <div margin-bottom="60px">
      <div style={{'marginBottom':'20px'}}>
        <ToggleButtonGroup type="checkbox">
          <ToggleButton id="altitudeButton" className="My-chart-selection-button My-altitude-button">altitude</ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup type="checkbox">
          <ToggleButton id="speedButton" className="My-chart-selection-button My-speed-button">speed</ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup type="checkbox">
          <ToggleButton className="My-chart-selection-button My-heartrate-button" onMouseDown={() => this.forceUpdate()}>heartrate</ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup type="checkbox">
          <ToggleButton className="My-chart-selection-button My-power-button" onMouseDown={() => this.forceUpdate()}>power</ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup type="checkbox">
          <ToggleButton id="powerButton" className="My-chart-selection-button My-cadence-button" onMouseDown={() => this.forceUpdate()}>cadence</ToggleButton>
        </ToggleButtonGroup>
        </div>
        <canvas
          id={this.state.id}
          width={this.props.width}
          height={this.props.height}
        />
        <SegmentAnalysis serverAccessToken={this.props.serverAccessToken}
          show={this.state.show}
          activityId={this.state.lastActivityId}
          segmentStart={this.state.segmentStart}
          segmentEnd={this.state.segmentEnd}
          onHandleClose={this.handleClose}
        />
      </div>
    );
  }

  getButtonClassName(item, defaultClass) {
    if (this.state.buttonStates[item]) {
      return defaultClass + "-on";
    } else {
      return defaultClass + "-off";
    }
  }

  componentDidUpdate() {
    if (
      this.state.lastActivityId === undefined ||
      this.state.lastActivityId !== this.props.activity.id
    ) {
      var usedSampleIndexes = [];
      var altitudeSet = [];
      var speedSet = [];
      var timeSet = [];
      var sequenceSet = [];
      if (this.props.activity !== undefined) {
        let samplesCount = this.props.activity.samples.length;
        var i = 0;
        var j = 0;
        this.props.activity.samples.forEach(sample => {
          if (i > samplesCount / SAMPLES_RESOLUTION) {
            sequenceSet.push(sample.sequence);
            altitudeSet.push(Math.round(sample.altitude));
            speedSet.push(Math.round(sample.speed));
            var date = new Date(sample.timestamp);
            timeSet.push(
              date.getHours() +
              ":" +
              date.getMinutes() +
              ":" +
              date.getSeconds()
            );
            usedSampleIndexes.push(j);
            i = 0;
          }
          i++;
          j++;
        });
        this.populateGraph(usedSampleIndexes, timeSet, altitudeSet, speedSet);
      }
    }
    if (this.props.activity && this.state.lastActivityId !== this.props.activity.id) {
      this.setState({
        lastActivityId: this.props.activity.id
      });
    }
  }

  handleClose() {
    this.setState({ show: false });
  }

  populateGraph(usedSampleIndexes, xDataSet, yDataSet, yDataSet2) {
    const Chart = window.Chart;
    var canvas = document.getElementById(this.state.id);
    var ctx = canvas.getContext("2d");
    ctx.height = "200px";
    if (this.state.lastChart !== undefined) {
      this.state.lastChart.destroy();
    }
    this.setState({
      lastChart: new Chart(ctx, {
        type: "line",
        data: {
          labels: xDataSet,
          datasets: [
            {
              label: "altitude",
              yAxisID: "altitude",
              data: yDataSet,
              pointRadius: 1,
              pointHitRadius: 5,
              backgroundColor: "rgba(38,37,51,0.4)",
              borderColor: "rgba(38,37,51,0.4)",
              borderWidth: 0.5
            },
            {
              label: "speed",
              yAxisID: "speed",
              data: yDataSet2,
              pointRadius: 0,
              backgroundColor: "rgba(54, 162, 235, 0.2)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 0.5
            }
          ]
        },
        options: {
          events: ["mouseup", "mousedown", "click", "mousemove"],
          hover: {
            intersect: false,
            onHover: function (evt, item) {
              if (item.length) {
                self.props.onChartHover(usedSampleIndexes[item[0]._index]);
                //console.log("item index: ", item[0]._index, evt.type);
              }
              //var a = 1;
            }
          },
          maintainAspectRatio: true,
          spanGaps: true,
          legend: {
            display: false
          },
          scales: {
            yAxes: [
              {
                id: "altitude",
                ticks: {
                  fontColor: "rgba(153,163,240,1)",
                  fontFamily: "Economica",
                  beginAtZero: false
                },
                gridLines: {
                  display: false
                },
              },
              {
                display: false,
                id: "speed",
                position: "right",
                ticks: {
                  max: 70,
                  beginAtZero: true,
                  display: false
                }
              }
            ],
            xAxes: [
              {
                display: false
              }
            ]
          },
          plugins: {
            zoom: {
              pan: {
                enabled: false,
                mode: "xy"
              },
              zoom: {
                drag: true,
                enabled: false,
                speed: 0.1,
                mode: "x",
                rangeMin: {
                  // Format of min zoom range depends on scale type
                  x: 100,
                  y: 100
                }
              }
            }
          }
        }
      })
    })
  }
}

/* background colorsets:
backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                ]
           borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],*/

export default SegmentAnalysisLineChart;