import Moment from 'moment';

export function testFunction( a){
    return a;
}

export function formatDate( date, formatType){
    var formattedDate = Moment(date).format(formatType);
    return formattedDate;
}

export function timestampFormatter(cell) {
    return formatDate( Date(cell).toString(), "DD.MM.YYYY HH:mm");
    //return new Date(cell).toDateString()
}

export function distanceFormatter(cell) {
    return Math.round(cell)/1000;
}

export function avgSpeedFormatter(cell) {
    return Math.round(cell);
}

export function createSamplesFromSamplesContainer(samplesContainer)
{
    if (samplesContainer == null){
        return [];
    }

    var lap = {};
    lap.samples = [];
    lap.sampledUnit = this;
    for (var i = 0; i < samplesContainer.sequence.length; i++){
        var sample = {};
        sample.sequence = (samplesContainer.sequence[i]);
        sample.timestamp = samplesContainer.timestamp[i];
        sample.distance = (samplesContainer.distance[i]);
        sample.duration = (samplesContainer.duration[i]);
        sample.altitude = (samplesContainer.altitude[i]);
        sample.heartRate = samplesContainer.heartRate[i];
        sample.power = samplesContainer.power[i];
        sample.cadence = samplesContainer.cadence[i];
        sample.speed = (samplesContainer.speed[i]);
        sample.longitude = (samplesContainer.longitude[i]);
        sample.latitude = (samplesContainer.latitude[i]);
        sample.temperature = samplesContainer.temperature[i];
        sample.altitudeGain = (samplesContainer.altitudeGain[i]);
        sample.altitudeGainPerUnit = (samplesContainer.altitudeGainPerUnit[i]);
        lap.samples.push(sample);
        sample.lap = lap;
    }
    return lap.samples;
}

export function getTimestamp(activity) {
    if (activity !== undefined) {
        var date = new Date(activity.timestamp);
        return date.toISOString();
    } else {
        return "...";
    }
}

export function secondsToTimestamp(seconds){
    let minutes = Math.floor(seconds / 60);
    let residuum = seconds % 60;
    let residuumFormatted = (residuum < 10) ? '0' + residuum.toString() : residuum.toString();      // dve števki
    let formattedText = minutes + ":" + residuumFormatted
    return formattedText;
    /*var date = new Date(null);
    date.setSeconds(seconds); // specify value for SECONDS here
    return date.toISOString();*/
}