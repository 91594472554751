import React from "react";
import Card from "../components/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faClockRotateLeft, faMedal, faSquarePollVertical} from "@fortawesome/free-solid-svg-icons";
import {Image} from "react-bootstrap";
import appHome from "../images/app/app-home-dark.png";
import videoMov from "../videos/app-store-promo.mov";
import videoMp4 from "../videos/app-store-promo.mp4";
import MobileStoreButton from "react-mobile-store-button";

class OverviewSection extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            wideScreen: false,
            innerWidth: 0
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    resize() {
        if (window.innerWidth > 1400){
            this.setState({
                wideScreen: true,
                innerWidth: window.innerWidth
            })
        }
        else {
            this.setState({
                wideScreen: false,
                innerWidth: window.innerWidth
            })
        }
    }

    render() {
        return (
            <div className={(this.state.innerWidth < 1200 || this.state.innerWidth > 1680) ? "container": ""} style={{minHeight: "450px", justifyContent: "center", alignItems: "center"}}>
                <div className="row align-items-center" style={{width: "100%"}}>
                    <div className={this.state.innerWidth > 1400 ? "col-md-9" : "col-md-8"}>
                        <div style={{paddingTop: this.state.innerWidth > 1200 ? "96px" : "24px", paddingBottom: "24px", paddingLeft: (this.state.innerWidth < 1200 || this.state.innerWidth > 1680) ? "0px": "48px"}}>
                            <div className="row align-items-center">
                                <div className="col-xl-4">
                                    <Card title="COMPLETE CYCLING EXPERIENCE" details="Designed to be used on the road or while spinning indoors" icon={<FontAwesomeIcon className="fa-2xl" icon={faCheckCircle} color="#00D7D5" />} />
                                </div>
                                <div className="col-xl-4">
                                    <Card title="FEATURE PACKED ACTIVITY TRACKING" details="Next-level app ergonomics and an unmatched user experience" icon={<FontAwesomeIcon className="fa-2xl" icon={faCheckCircle} color="#00D7D5" />} />
                                </div>
                                <div className="col-xl-4">
                                    {/*<Card title="ADVANCED ANALYTICS" details="Highest standards in data analysis and athlete performance evaluation" icon={<FontAwesomeIcon className="fa-2xl" icon={faClockRotateLeft} color="#00D7D5" />} />*/}
                                    {/*<Card title="CUTTING-EDGE ANALYTICS" details="to optimize your cycling performance and achieve your goals" icon={<FontAwesomeIcon className="fa-2xl" icon={faClockRotateLeft} color="#00D7D5" />}/>*/}
                                    <Card title="CUTTING-EDGE ANALYTICS" details="Achieve your goals with our advanced analytics and performance evaluation" icon={<FontAwesomeIcon className="fa-2xl" icon={faCheckCircle} color="#00D7D5" />}/>
                                </div>
                            </div>
                            <div className="d-none d-md-block">
                                <p style={{fontStyle: "italic", marginTop: "48px"}}>Upgrade to our product today and take your cycling experience to the next level</p>
                                <MobileStoreButton store="ios"
                                                   url="https://apps.apple.com/si/app/saddle-addict/id1589658144"
                                                   linkProps={{ title: 'iOS Store Button' }}
                                />
                            </div>
                        </div>
                        {/*<table style={{tableLayout: "fixed", width: "100%", marginTop: "8px"}}>
                                            <td style={{width: "50%"}}>
                                                <OverviewCard title="Designed for the Hardcore Cyclist" icon={<FontAwesomeIcon className={this.isPhone() ? "fa-3x" : "fa-4x"} icon={faCheckCircle} color="#0FBAF8" />} />
                                                <OverviewCard title="Feature Packed Activity Tracking" icon={<FontAwesomeIcon className={this.isPhone() ? "fa-3x" : "fa-4x"} icon={faHeartPulse} color="#0FBAF8" />} />
                                            </td>
                                            <td style={{width: "50%"}}>
                                                <OverviewCard title="Next Level App Ergonomics" icon={<FontAwesomeIcon className={this.isPhone() ? "fa-3x" : "fa-4x"} icon={faMobilePhone} color="#0FBAF8" />} />
                                                <OverviewCard title="Unmatched User Experience" icon={<FontAwesomeIcon className={this.isPhone() ? "fa-3x" : "fa-4x"} icon={faMedal} color="#0FBAF8" />} />
                                            </td>
                                        </table>
                                        <MobileStoreButton store="ios"
                                                           url="https://apps.apple.com/si/app/saddle-addict/id1589658144"
                                                           linkProps={{ title: 'iOS Store Button' }}
                                        />*/}
                    </div>
                    <div className={this.state.innerWidth > 1400 ? "col-md-3" : "col-md-4"}  style={{position: "relative", zIndex: "999"}}>
                        <div className="d-block d-sm-block d-md-none d-lg-none" style={{height: "120px"}}/>
                        <div style={{height: "300px"}}></div>
                        <Image className="video-absolute-position" src={appHome}/>
                        <video style={{borderWidth: "10px",
                            borderColor: "black",
                            borderRadius: "45px",
                            padding: "17px"}} autoPlay loop muted solid playsInline width="100%" max className="video-absolute-position">
                            <source src={videoMov} type="video/quicktime"/>
                            <source src={videoMp4} type="video/mp4"/>
                        </video>
                        <div className="d-block d-sm-block d-md-none d-lg-none" style={{height: "120px"}}/>
                    </div>
                    <div className="d-md-none" >
                        <p style={{fontStyle: "italic", marginTop: "16px"}}>Upgrade to our product today and take your cycling experience to the next level</p>
                        <MobileStoreButton store="ios"
                                           url="https://apps.apple.com/si/app/saddle-addict/id1589658144"
                                           linkProps={{ title: 'iOS Store Button' }}
                        />
                    </div>
                </div>
            </div>
        );
    };
}

export default OverviewSection;