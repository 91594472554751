import React from "react";

class SegmentAnalysisHistoryChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: Math.random(),
      lastChart: undefined
    };
  }

  shouldComponentUpdate(nextProps, nextState){
    if (nextProps.segments !== this.props.segments)
      return true;
    return false;
  }

  render() {
    return (
      <canvas
        id={this.state.id}
        width={this.props.width}
        height={this.props.height}
      />
    );
  }

  componentDidUpdate() {
    if (this.props.segments !== undefined) {
    var xValues = [];
        var yValues = [];
      this.props.segments.forEach(segment => {
          xValues.push(segment.timestamp);
          yValues.push(segment.duration);
      });
      this.populateGraph(xValues, yValues);
    }
  }

  populateGraph(xDataSet, yDataSet) {
    const Chart = window.Chart;
    var canvas = document.getElementById(this.state.id);
    var ctx = canvas.getContext("2d");
    if (this.state.lastChart !== undefined) {
      this.state.lastChart.destroy();
    }
    this.setState({
          lastChart: new Chart(ctx, {
            type: "bar",
            data: {
              labels: xDataSet,
              datasets: [
                {
                  label: "altitude",
                  data: yDataSet,
                  pointRadius: 5,
                  pointHitRadius: 5,
                  backgroundColor: "rgba(255, 99, 132, 0.4)",
                  borderColor: "rgba(255, 99, 132, 1)",
                  borderWidth: 0.5
                }
              ]
            },
            options: {
              legend: {
                display: false
              },
              spanGaps: true,
              scales: {
                yAxes: [
                  {
                    display: false,
                    ticks: {
                      beginAtZero: false
                    }
                  }
                ],
                xAxes: [{
                  display: false
                }]
              }
            }
          })
        });
  }
}

export default SegmentAnalysisHistoryChart;