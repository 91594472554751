import React from "react";
import powerCurveJson from "../sections/powerCurve.json";
import Chart from "chart.js";

class ChartEntity {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
}

class SectionsGraph extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.populateChart([30, 40, 36, 30, 40, 55, 60, 65, 55, 47, 59, 82, 104, 123, 147, 155, 160])
    }

    render(){
        return(
            <div>
                <canvas id={"sections-chart"} height="160" width="350px"/>
            </div>
        );
    }

    populateChart(profile){
        var ctx = document.getElementById('sections-chart').getContext('2d');

        let profileData = [];
        let selectedData = [];
        for (let i = 0; i < profile.length; i++) {
            const profilePoint = profile[i];
            profileData.push(new ChartEntity(i, profilePoint));
        }

        for (let i = 5; i < 14; i++) {
            const profilePoint = profile[i];
            selectedData.push(new ChartEntity(i, profilePoint));
        }


        let chart = new Chart(ctx, {
            type: 'line',
            data: {
                datasets: [{
                    label: 'First dataset',
                    data: profileData,
                    tension: 0.4,
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                    fill: true,
                    pointRadius: 0,
                    borderColor: "white",
                    borderWidth: 2.0
                },{
                    label: 'First dataset',
                    data: selectedData,
                    tension: 0.4,
                    backgroundColor: "white",
                    fill: true,
                    pointRadius: 0,
                    borderColor: "white",
                    borderWidth: 2.0
                }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 0,
                        right: 0
                    }
                },
                spanGaps: false,
                legend: {
                    display: false
                },
                animation: false,
                scales: {
                    yAxes: [
                        {
                            max: 160,
                            display: false,
                            ticks: {
                                padding: 0,
                                max: 160,
                                beginAtZero: true
                            }
                        }
                    ],
                    xAxes: [
                        {
                            type: 'linear',
                            display: false
                        }
                    ]
                },
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        });
    }
}

export default SectionsGraph