import React from "react";
import reliveMov from "../videos/sa-relive-264.mov";
import reliveMp4 from "../videos/sa-relive-264.mp4";
import bannerMov from "../videos/sa-video-2.mov";

class ReliveSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            innerWidth: window.innerWidth
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    resize() {
        this.setState({innerWidth: window.innerWidth})
    }

    render() {
        return(
            <div className={this.state.innerWidth > 550 ? "container" : "overflow-hidden"} style={{paddingTop: this.state.innerWidth > 991 ? "96px" : "48px", paddingBottom: this.state.innerWidth > 991 ? "96px" : this.state.innerWidth > 550 ? "48px" : "0px"}}>
                <div className="row align-items-center" style={{}}>
                    <div className="col-lg-5">
                        <p className="title">RELIVE</p>
                        <p className="">Experience the thrill of reliving your on-the-road adventures with our exquisitely crafted 3D representations.</p>
                        <p className="">Our immersive technology will transport you back to every moment, allowing you to relive the challenges and triumphs of your biking journey.</p>
                        <p className="">From the steep inclines to the thrilling descents, you can now enjoy the complete picture of your journey.</p>
                    </div>
                    <div className="col-lg-7" style={{display: "flex", alignItems: "end", justifyContent: "center"}}>
                        <video fluid autoPlay loop muted solid playsInline width="100%" maxHeight="320px" style={{padding: 0,
                            margin: 0,
                            borderRadius: this.state.innerWidth > 550 ? "20px" : "",
                            maxWidth: "550px"}}>
                            <source src={reliveMov} type="video/quicktime"/>
                            <source src={reliveMp4} type="video/mp4"/>
                            <div className="bck-image" style={{height: "500px"}}/>
                        </video>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReliveSection;