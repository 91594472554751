import React from "react";

class Loader extends React.Component {
    render() {
        return(
            <div id="loader-wrapper">
                <div className="loader">
                    <svg className="circular-loader" viewBox="25 25 50 50">
                        <circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke="gray"
                                stroke-width="5"/>
                    </svg>
                </div>
            </div>
        );
    }
}

export default Loader;