import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {Container} from "react-bootstrap";
import SAStylisedText from "./SAStylisedText";

class NaviagtionBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: "Administrator"
    };
    this.navItemClicked = this.navItemClicked.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }
  handleSelect(eventKey) {
    this.props.modeChanged(eventKey);
  }

  handleLogout(eventKey) {
    this.props.logout();
  }

  navItemClicked(item) {
    this.props.modeChanged(item);
  }

  render() {
    return (
      <Navbar collapseOnSelect bg="black" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="#home" onClick={() => this.handleSelect("main")}>
            <SAStylisedText width="100%" fontSize="25px" color="white" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-nav me-xl-1" onSelect={this.handleSelect}>
              <Nav.Link className="nav-link"  eventKey="ride">RIDE</Nav.Link>
              <Nav.Link className="nav-link" eventKey="workout">WORKOUT</Nav.Link>
              <Nav.Link className="nav-link" eventKey="analyse">ANALYSE</Nav.Link>
              <Nav.Link className="nav-link" eventKey="relive">RELIVE</Nav.Link>
              <Nav.Link className="nav-link" eventKey="sectors">SECTORS</Nav.Link>
              <Nav.Link className="nav-link" eventKey="pricing">PRICING</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default NaviagtionBar