import React from "react";

class LineWidget extends React.Component {
    render() {
        let strokeWidth = 12.0;
        let lineWidth = 300 - strokeWidth * 2;
        return(
            <div>
                <table width="100%">
                    <tr>
                        <td width="15%" style={{textAlign: "right", verticalAlign: "middle", minWidth: "80px", fontSize: 15, color: this.props.darkMode ? "white" : ""}}>{this.props.title}</td>
                        <td width="70%" style={{textAlign: "center", verticalAlign: "middle"}}>
                            <div >
                                <div className="svg-container">
                                    <svg className="svg-content" viewBox="0 0 300 24" preserveAspectRatio="none">
                                        <line className="bottom" style={{stroke: this.props.darkMode ? "#485460" : ""}} x1={strokeWidth} y1={strokeWidth} x2={lineWidth + strokeWidth} y2={strokeWidth} strokeWidth={strokeWidth} strokeLinecap="round"/>
                                        {this.drawTopBar(this.props.ppu, strokeWidth, lineWidth)}
                                    </svg>
                                </div>
                            </div>
                        </td>
                        <td width="10%" style={{textAlign: "left", verticalAlign: "middle", minWidth: "50px", fontSize: 15, color: this.props.darkMode ? "white" : ""}}>{this.props.value}</td>
                    </tr>
                </table>
            </div>
        );
    }

    drawTopBar(ppu, strokeWidth, lineWidth){
        if (ppu > 0){
            return(
                <line className="animate" x1={strokeWidth} y1={strokeWidth} x2={ppu * lineWidth + strokeWidth} y2={strokeWidth} stroke={this.props.color} strokeWidth={strokeWidth} strokeLinecap="round"/>
            );
        }
    }
}

export default LineWidget;