import React from "react";
import MobileStoreButton from "react-mobile-store-button";
import {Button} from "react-bootstrap";

class GetStarted extends React.Component {
    render() {
        return(
            <div className="section7">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <p className="title">TRY FOR FREE</p>
                            <p>Download SADDLE ADDICT app and join the most advanced cycling service available today</p>
                            <p style={{margin: "0px", fontStyle: "italic"}}>MONTHLY PLAN</p>
                            <p style={{fontWeight: 900, color: "black"}}>5.99 €/ month</p>
                            <p style={{margin: "0px", fontStyle: "italic"}}>ANNUAL PLAN</p>
                            <p style={{color: "black", paddingBottom: "0px", marginBottom: "0px"}}>49.99 €/ year</p>
                            <p style={{fontWeight: 1000, color: "black", paddingTop: "0px", marginTop: "0px"}}>4.16 € month equivalent</p>
                            <p>Try out the full experience risk-free. Before you make a commitment, we offer a <strong style={{fontWeight: 1000, color: "black"}}>1-MONTH Free Trial</strong> of the most comprehensive riding experience.</p>
                            {/*<a href="https://apps.apple.com/si/app/saddle-addict/id1589658144">
                                <Button>Start Yout Free Month</Button>
                            </a>*/}
                            <p style={{marginBottom: "48px"}}>To start your Free Trial:</p>
                            <MobileStoreButton
                                store="ios"
                                url="https://apps.apple.com/si/app/saddle-addict/id1589658144"
                                linkProps={{ title: 'iOS Store Button' }}
                            />
                            <p style={{fontSize: "10px", fontStyle: "italic", marginTop: "48px"}}>These are net prices before taxes. Depending on your location, VAT and conversion rates may apply</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GetStarted;