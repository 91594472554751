import React from "react";
import {Slide} from "react-slideshow-image";

class Slideshow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedSlideIdx: 0,
        }
        this.slideRef = React.createRef();
    }

    handleSlideChange = (oldIndex, newIndex) => {
        this.setState({
            selectedSlideIdx: newIndex
        })
    };

    setIndex = (idx) =>{
        this.slideRef.current.goTo(idx);
    }

    render(){
        return(
            <div>
                <div style={{height: this.props.height}}>
                    <Slide ref={this.slideRef} canSwipe={false} onChange={this.handleSlideChange} duration={this.props.duration} transitionDuration="200" arrows={false} pauseOnHover={false}>
                        {this.props.images.map((image, idx) => (
                            <div>
                                <div className="slideshow-slide" style={{backgroundImage: `url(${image})`, height: this.props.height, backgroundSize: "cover", backgroundPosition: this.props.centering[idx]}}>
                                </div>
                            </div>
                        ))}
                    </Slide>
                </div>
                <div style={{display: "flex", position: "relative", alignItems: "center", justifyContent: "center"}}>
                    <div style={{display: "flex", position: "absolute", marginBottom: "50px"}}>
                        {this.props.images.map((image, index) => (
                            <div className="slideshowDots">
                                <div onClick={() => {this.setIndex(index);}}
                                     className={`slideshowDot${index === this.state.selectedSlideIdx ? " active" : ""}`}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default Slideshow;