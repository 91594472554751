import React from "react";
import {Image} from "react-bootstrap";
import simParis from "../images/final/sim-paris.jpg"
import simBarcelona from "../images/final/sim-barcelona.jpg"
import simTorino from "../images/final/sim-torino.jpg"
import simAzure1 from "../images/final/sim-azure-1.jpg"
import simAzure2 from "../images/final/sim-azure-2.jpg"
import simCorsica from "../images/final/sim-corsica.jpg"
import simMonaco from "../images/final/sim-monaco.jpg"
import simSanMarino from "../images/final/sim-san-marino.jpg"
import simSanRemo from "../images/final/sim-san-remo.jpg"
import simTaormina from "../images/final/sim-taormina.jpg"
import simVerdon from "../images/final/sim-verdon.jpg"
import powerCurveJson from "./powerCurve.json";
import Chart from "chart.js";

class ChartEntity {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
}

class EpicRide{
    constructor(title, image, distance, totalClimb, profile, max) {
        this.title = title;
        this.image = image;
        this.distance = distance;
        this.totalClimb = totalClimb;
        this.profile = profile;
        this.max = max
    }
}

let epicRides = [new EpicRide("The Superga Climb", simTorino, "13.96 km", "549 m", [30, 31, 29, 32, 30, 33, 30, 31, 30, 32, 30, 51, 69, 92, 108, 131, 149], 300),
    new EpicRide("Champs-Elysees", simParis, "6.73 km", "41 m", [50, 51, 49, 58, 68, 59, 50, 51, 50, 52, 57, 51, 53, 59, 53, 52, 50], 500),
    new EpicRide("Corsica", simCorsica, "1.91 km", "74 m", [10, 11, 18, 15, 19, 13, 10, 11, 25, 52, 63, 72, 90, 115, 110, 70, 10], 700),
    new EpicRide("Foothills of Etna", simTaormina, "8.37 km", "318 m", [30, 41, 45, 55, 70, 87, 101, 108, 119, 145, 147, 142, 115, 95, 55, 40, 30], 500),
    new EpicRide("Nice, French Riviera", simAzure1, "13.25 km", "408 m", [36, 45, 57, 73, 87, 101, 108, 119, 139, 145, 131, 112, 95, 72, 47, 30, 32], 400),
    new EpicRide("Monaco Prologue", simMonaco, "3.28 km", "122 m", [10, 12, 45, 75, 102, 93, 71, 59, 23, 25, 12, 13, 17, 12, 9, 5, 7], 700),
    new EpicRide("Cote d'Azur", simAzure2, "19.56 km", "685 m", [5, 7, 29, 27, 33, 27, 25, 27, 33, 27, 22, 33, 42, 55, 87, 113, 82], 300),
    new EpicRide("Verdon, French Alps", simVerdon, "21.72 km", "904 m", [30, 40, 37, 39, 40, 55, 60, 55, 57, 75, 83, 100, 125, 140, 150, 140, 130], 300),
    new EpicRide("Barcelona Olympic Park", simBarcelona, "7.35 km", "109 m", [30, 40, 55, 65, 55, 65, 70, 85, 100, 70, 30, 70, 80, 85, 70, 55, 40], 500),
    new EpicRide("SanMarino", simSanMarino, "13.54 km", "704 m", [30, 40, 36, 30, 40, 55, 60, 65, 55, 47, 59, 82, 104, 123, 147, 155, 160], 300),
    new EpicRide("San Remo", simSanRemo, "27.79 km", "580 m", [30, 31, 45, 57, 71, 85, 65, 31, 30, 32, 30, 32, 47, 62, 45, 30, 29], 300)];

class EpicRidesSection extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            imageWidth: "500px",
            imageHeight: "330px",
            listViewHeight: "470px",
            listViewWidth: "7500px",
            bodyFontSize: "16px"
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
        epicRides.map((epicRide, idx) => (
            this.populateChart(idx, epicRide.profile, epicRide.max)
        ));
    }

    resize() {
        // določimo dimenzije
        if (window.innerWidth > 1000){
            this.setState({
                imageWidth: "400px",
                imageHeight: "264px",
                listViewHeight: "404px",
                listViewWidth: "4576px",
                bodyFontSize: "16px"
            })
        }
        else{
            this.setState({
                imageWidth: "321px",
                imageHeight: "220px",
                listViewHeight: "370px",
                listViewWidth: "3707px",
                bodyFontSize: "16px"
            })
        }
    }

    render(){
        return(
            <div className="section-epic-rides">
                <div className="container" style={{maxWidth: "1000px"}}>
                    <p className="title">VIRTUAL RIDES</p>
                    <p style={{fontSize: this.state.bodyFontSize, marginTop: "30px"}}>Experience stunning 3D virtual cycling and explore legendary destinations with our expertly crafted selection, suitable for every type of cyclist.</p>
                    <p style={{fontSize: this.state.bodyFontSize}}>Whether you're a lightweight climber, an all-around rolleur, or a heavy-weight sprinter, our product offers the perfect virtual ride for your cycling goals!</p>
                </div>
                <div className="horizontal-list">
                    <ul className="epic-rides" style={{width: this.state.listViewWidth, height: this.state.listViewHeight}}>
                        {epicRides.map((epicRide, idx) => (
                            <li className="epic-rides" style={{width: this.state.imageWidth, height: this.state.imageHeight}}>
                                <div className="epic-rides-background" style={{position: "relative", backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(${epicRide.image})`}}>
                                    <canvas id={"chart-" + idx} height={this.state.imageHeight} width={this.state.imageWidth}/>
                                </div>
                                <p className="epic-rides-title">{epicRide.title}</p>
                                <table>
                                    <tr>
                                        <td width="120px">
                                            <p className="epic-rides-quantity">distance: </p>
                                        </td>
                                        <td>
                                            <p className="epic-rides-value">{epicRide.distance}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className="epic-rides-quantity">total climb: </p>
                                        </td>
                                        <td>
                                            <p className="epic-rides-value">{epicRide.totalClimb}</p>
                                        </td>
                                    </tr>
                                </table>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }

    populateChart(id, profile, max){
        var ctx = document.getElementById('chart-' + id).getContext('2d');

        let powerCurve = JSON.parse(JSON.stringify(powerCurveJson))
        let data = [];

        let min = Math.min(...profile)
        let buffer = 24 - min;

        for (let i = 0; i < profile.length; i++) {
            const profilePoint = profile[i];
            data.push(new ChartEntity(i, profilePoint + (buffer > 0 ? buffer : 0)));
        }

        let chart = new Chart(ctx, {
            type: 'line',
            data: {
                datasets: [{
                    label: 'First dataset',
                    data: data,
                    tension: 0.4,
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                    fill: true,
                    pointRadius: 0,
                    borderColor: "white",
                    borderWidth: 2.0
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 0,
                        right: 0
                    }
                },
                spanGaps: false,
                legend: {
                    display: false
                },
                animation: false,
                scales: {
                    yAxes: [
                        {
                            max: max,
                            display: false,
                            ticks: {
                                padding: 0,
                                max: max,
                                beginAtZero: true
                            }
                        }
                    ],
                    xAxes: [
                        {
                            type: 'linear',
                            display: false
                        }
                    ]
                },
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        });
    }

}

export default EpicRidesSection