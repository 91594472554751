import React from "react";

class SAStylisedText extends React.Component {
    render() {
        return(
            <table style={{display: "grid", justifyContent: "center", alignItems: "center"}}>
                <tr style={{fontSize: this.props.fontSize, color: this.props.color}}>
                    <td style={{fontWeight: '200', fontFamily: "Montserrat"}} >SADDLE</td>
                    <td style={{width: "8px"}} ></td>
                    <td style={{fontWeight: '800', fontFamily: "Montserrat"}} >ADDICT</td>
                </tr>
            </table>
        );
    }
}

export default SAStylisedText;