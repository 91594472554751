import React from "react";
import mapboxgl from "mapbox-gl";
import ReactMapGL from "react-map-gl";
import PolylineOverlay from "./PolylineOverlay";
import { createSamplesFromSamplesContainer }  from './Helper.js';
import structuredClone from '@ungap/structured-clone';

var token =
    "pk.eyJ1IjoiYnJvemljIiwiYSI6ImNqdWIzemFqaDA4dHozeXBkdjFrZnIzN2YifQ.toKHMHsCGOVaGY_IO9Gdzw";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
class Map extends React.Component {
    /*render(){
          return(
                  <div id="map" style={{position: 'absolute', top: 1000, bottom: 0, width: '30%', height: '30%',}}/>
          );
      }*/

    constructor(props) {
        super(props);
        this.state = {
            activityId: undefined,
            viewport: {
                width: "fit",
                longitude: 6.0648,
                latitude: 45.0909,
                zoom: 10
            },
            settings: {
                dragPan: true,
                dragRotate: true,
                scrollZoom: false,
                touchZoom: false,
                touchRotate: false,
                keyboard: false,
                doubleClickZoom: true,
                trackResize: true
            }
        };
        this.reload = true;
    }

    render() {
        var points = undefined;
        var hoverPairs = [];
        var activity = undefined;
        if (this.props.data && this.props.data.length > 0)
        {
            activity = this.props.data[0].activity;
            activity.samples = createSamplesFromSamplesContainer(activity.samplesContainer);
            points = activity.samples.map(function(sample) {
                var coordinataPair = [sample.longitude, sample.latitude];
                return coordinataPair;
            });

            this.props.data.forEach((datum)=>{
                // 30.8.2022: nultih koordinate ne smemo dodajati na sezna, saj nam v tem primeru odleti on hover...
                if (datum.index && datum.activity.samples[datum.index].longitude !== null && datum.activity.samples[datum.index].latitude !== null)
                {
                    var hoverPoints = [
                        datum.activity.samples[datum.index].longitude,
                        datum.activity.samples[datum.index].latitude
                    ];
                    hoverPairs.push(hoverPoints);
                }
            });
        }

        if (activity) {
            if (activity.id !== this.state.activityId) {
                let mapCenter = this.determineMapCenter(activity);
                this.reload = false;
                this.setState({
                    activityId: activity.id,
                    viewport: {
                        width: "fit",
                        height: 400,
                        longitude: mapCenter.longitudeCenter,
                        latitude: mapCenter.latitudeCenter,
                        zoom: mapCenter.zoom
                    }
                });
            }
        }

        return (
            <div className="map rounded shadow-lg">
                <ReactMapGL
                    {...this.state.viewport}
                    {...this.state.settings}
                    mapStyle="mapbox://styles/mapbox/outdoors-v11"
                    onViewportChange={nextViewport =>
                        this.setState({ viewport: { ...nextViewport, width: "fit" } })
                    }
                    mapboxApiAccessToken={token}
                >
                    {(() => {
                        if (points) {
                            return (
                                <PolylineOverlay points={points} hoverPairs={hoverPairs} />
                            );
                        }
                    })()}
                </ReactMapGL>
            </div>
        );
    }

    determineMapCenter(activity) {
        let longitudeCenter = 6.0648;
        var latitudeCenter = 45.0909;
        var zoom = 13;
        if (activity) {
            if (activity.samples) {
                let longitudes = activity.samples.map(function(sample) {
                    return sample.longitude;
                });

                let latitudes = activity.samples.map(function(sample) {
                    return sample.latitude;
                });

                let maxLongitude = Math.max.apply(null, longitudes.filter(Boolean));
                let minLongitude = Math.min.apply(null, longitudes.filter(Boolean));
                let maxLatitude = Math.max.apply(null, latitudes.filter(Boolean));
                let minLatitude = Math.min.apply(null, latitudes.filter(Boolean));

                longitudeCenter = (minLongitude + maxLongitude) / 2;
                latitudeCenter = (minLatitude + maxLatitude) / 2;

                let latitudeSpan = maxLatitude - minLatitude;
                let longitudeSpan = maxLongitude - minLongitude;

                // dolocimo nivoje zuma po LAT
                var latZoom = zoom;
                if (latitudeSpan >= 0.0125 && latitudeSpan < 0.025) latZoom = 13;
                if (latitudeSpan >= 0.025 && latitudeSpan < 0.05) latZoom = 12;
                if (latitudeSpan >= 0.05 && latitudeSpan < 0.1) latZoom = 11;
                if (latitudeSpan >= 0.1 && latitudeSpan <0.2 ) latZoom = 10;
                if (latitudeSpan >= 0.2 && latitudeSpan <0.3 ) latZoom = 9;
                if (latitudeSpan >= 0.3 && latitudeSpan <0.6 ) latZoom = 8;
                if (latitudeSpan >= 0.6 && latitudeSpan <1.2 ) latZoom = 7;
                if (latitudeSpan >= 1.2) latZoom = 6;

                // dolocimo nivoje zuma po LON
                var longZoom = zoom;
                if (longitudeSpan >= 0.03125 && longitudeSpan < 0.0625) longZoom = 13;
                if (longitudeSpan >= 0.0625 && longitudeSpan < 0.125) longZoom = 12;
                if (longitudeSpan >= 0.125 && longitudeSpan < 0.25) longZoom = 11;
                if (longitudeSpan >= 0.25 && longitudeSpan < 0.5) longZoom = 10;
                if (longitudeSpan >= 0.5 && longitudeSpan < 1.0 ) longZoom = 9;
                if (longitudeSpan >= 1.0 && longitudeSpan < 2.0 ) longZoom = 8;
                if (longitudeSpan >= 2.0 && longitudeSpan < 4.0 ) longZoom = 7;
                if (longitudeSpan >= 4.0) longZoom = 6;

                zoom = Math.min.apply(null, [longZoom, latZoom]);
            }
        }

        return {
            longitudeCenter: longitudeCenter,
            latitudeCenter: latitudeCenter,
            zoom: zoom
        };
    }

    componentDidMount() {
        //this.process()
      //  this.forceUpdate();
      var settingstmp = structuredClone(this.state.settings);
      this.setState({
        settings: settingstmp
    });
    }

    redraw({ project }) {
        const [cx, cy] = project([-122, 37]);
        return <circle cx={cx} cy={cy} r={4} fill="blue" />;
    }

    process() {
        //var truckLocation = [-83.093, 42.376];
        //var warehouseLocation = [-83.083, 42.363];
        //var lastQueryTime = 0;
        //var lastAtRestaurant = 0;
        //var keepTrack = [];
        //var currentSchedule = [];
        //var currentRoute = null;
        //var pointHopper = {};
        //var pause = true;
        //var speedFactor = 50;

        // Add your access token
        //const mapboxgl = window.mapboxgl;
        mapboxgl.accessToken =
            "pk.eyJ1IjoiYnJvemljIiwiYSI6ImNqdWIzemFqaDA4dHozeXBkdjFrZnIzN2YifQ.toKHMHsCGOVaGY_IO9Gdzw";

        // Initialize a map
        //var map = new mapboxgl.Map({
        //  container: "map", // container id
        //  style: "mapbox://styles/mapbox/dark-v9", // stylesheet location
        //  center: truckLocation, // starting position
        //  zoom: 12 // starting zoom
        //});
    }
}

export default Map;