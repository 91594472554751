import React from "react";
import LineWidget from "./LineWidget";
import {secondsToTimestamp} from "./Helper";

class PowerZones extends React.Component {
    render() {
        let zones = this.getZones(this.props.activity);
        if(zones === undefined) {
            return(
                <div />
            ); 
        }
        let maxDuration = zones.reduce((partial, n) => n > partial ? n : partial, 0);
        let duration = maxDuration > 0 ? maxDuration : 1;
        return(
            <div className="container rounded frame">
                <div className="row">
                    <div className="col-lg-3" />
                    <div className="col-lg-6">
                        <p className="title-small"> Power Zones</p>
                        <LineWidget title="Neurom." value={secondsToTimestamp(zones[6])} ppu={zones[6] / duration} color="#EF5777"/>
                        <LineWidget title="Anaerobic" value={secondsToTimestamp(zones[5])} ppu={zones[5] / duration} color="#FFA801"/>
                        <LineWidget title="VO2 max" value={secondsToTimestamp(zones[4])} ppu={zones[4] / duration} color="#FFD32A"/>
                        <LineWidget title="Threshold" value={secondsToTimestamp(zones[3])} ppu={zones[3] / duration} color="#0FBAF8"/>
                        <LineWidget title="Tempo" value={secondsToTimestamp(zones[2])} ppu={zones[2] / duration} color="#4ACFF9"/>
                        <LineWidget title="Endurance" value={secondsToTimestamp(zones[1])} ppu={zones[1] / duration} color="#00D7D5"/>
                        <LineWidget title="Recovery" value={secondsToTimestamp(zones[0])} ppu={zones[0] / duration} color="#34E7E3"/>
                    </div>
                    <div className="col-lg-3" />
                </div>
            </div>
        );
    }

    getZones(activity){
        if (activity === undefined){
            //return [0, 0, 0, 0, 0, 0, 0];
            return;
        }
        let ftp = activity.userFtp;
        let power = activity.percentileCurves
        .find(x => x.percentileCurveType === "power");
        if(power === undefined)
            return;

        let percentileCurvePoints = power.percentileCurvePoints;

        // Active recovery
        let zone1Duration = percentileCurvePoints
            .filter(x => x.value <= ftp * 0.55)
            .map(x => x.duration)
            .reduce((partial, n) => n > partial ? n : partial, 0);

        // Endurance
        let zone2Duration = percentileCurvePoints
            .filter(x => x.value > ftp * 0.55 && x.value <= ftp * 0.75)
            .map(x => x.duration)
            .reduce((partial, n) => n > partial ? n : partial, 0);

        // Tempo
        let zone3Duration = percentileCurvePoints
            .filter(x => x.value > ftp * 0.75 && x.value <= ftp * 0.90)
            .map(x => x.duration)
            .reduce((partial, n) => n > partial ? n : partial, 0);

        // Threshold
        let zone4Duration = percentileCurvePoints
            .filter(x => x.value > ftp * 0.90 && x.value <= ftp * 1.05)
            .map(x => x.duration)
            .reduce((partial, n) => n > partial ? n : partial, 0);

        //VO2 max
        let zone5Duration = percentileCurvePoints
            .filter(x => x.value > ftp * 1.05 && x.value <= ftp * 1.20)
            .map(x => x.duration)
            .reduce((partial, n) => n > partial ? n : partial, 0);

        // Anaerobic
        let zone6Duration = percentileCurvePoints
            .filter(x => x.value > ftp * 1.20 && x.value <= ftp * 1.50)
            .map(x => x.duration)
            .reduce((partial, n) => n > partial ? n : partial, 0);

        // Neuromuscular
        let zone7Duration = percentileCurvePoints
            .filter(x => x.value > ftp * 1.50)
            .map(x => x.duration)
            .reduce((partial, n) => n > partial ? n : partial, 0);

        return [zone1Duration - zone2Duration,
            zone2Duration - zone3Duration,
            zone3Duration - zone4Duration,
            zone4Duration - zone5Duration,
            zone5Duration - zone6Duration,
            zone6Duration - zone7Duration,
            zone7Duration];
    }
}

export default PowerZones;