import React from "react";
import IntervalTraining, {Interval} from "../components/IntervalTraining";
import programJson from "../sections/programs.json";
import {secondsToTimestamp} from "../activity/Helper";

let programs = JSON.parse(JSON.stringify(programJson));
let selectedPrograms = programs.filter(program => (program.title === "The Trial" ||
    program.title === "Recovery day" ||
    program.title === "Endure this!" ||
    program.title === "Twin Peaks" ||
    program.title === "Alter Crib" ||
    program.title === "Alter Peak" ||
    program.title === "Drills" ||
    program.title === "Through thick and thin" ||
    program.title === "Triple spike" ||
    program.title === "HIIT classic" ||
    program.title === "Your darkest hour" ||
    program.title === "Mood swings" ||
    program.title === "Slow and steady wins the race" ||
    program.title === "Oh, the FTP..."))
class UixSection extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render(){
        let width = 350;
        return(
            <div style={{backgroundColor: "rgb(247,247,247)"}}>
                <div className="container" style={{maxWidth: "1000px", paddingTop: "48px"}}>
                    <p className="title">INTERVAL TRAININGS</p>
                    <p>Looking for an outstanding workout session? Look no further than our ERG interval training program on your smart turbo trainer!</p>
                    <p>Whether you're looking to recover from a tough day or push yourself to the limit with a high-intensity session, our range of workouts has got you covered! If that's not enough, you can design your own personalized interval training sessions effortlessly using our in-app training creator.</p>
                </div>
                <div className="horizontal-list">
                    <ul className="epic-rides" style={{width: selectedPrograms.length * width + selectedPrograms.length * 16, height: this.state.listViewHeight}}>
                        {selectedPrograms.map(program => (
                            <li className="epic-rides" style={{width: width}}>
                            <IntervalTraining program={program} width={width}/>
                                <p className="epic-rides-title">{program.title}</p>
                                <table>
                                    <tr>
                                        <td width="120px">
                                            <p className="epic-rides-quantity">duration: </p>
                                        </td>
                                        <td>
                                            <p className="epic-rides-value">{secondsToTimestamp(program.duration)}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className="epic-rides-quantity">intensity: </p>
                                        </td>
                                        <td>
                                            <p className="epic-rides-value">{Math.round(program.intensityFactor * 100)} %</p>
                                        </td>
                                    </tr>
                                </table>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}

export default UixSection;