import React from "react";
// import selectPlugin from "chartjs-plugin-select";  // plugin za drag&select
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import Chart from 'chart.js';
import Button from "react-bootstrap/Button";

let SAMPLES_RESOLUTION = 3600;

class ChartEntity {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
}

var self = undefined;
class LineChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: Math.random(),
            lastChart: undefined,
            selectedQuantity: "speed",
            altitudeSet: null,
            speedSet: null,
            cadenceSet: null,
            powerSet: null,
            heartrateSet: null,
            programSet: null,
            usedSampleIndexes: null
        };
        this.segmentSelected = this.segmentSelected.bind(this);
        this.chartHover = this.chartHover.bind(this);
        this.speedSelected = this.speedSelected.bind(this);
        this.hrSelected = this.hrSelected.bind(this);
        this.powerSelected = this.powerSelected.bind(this);
        this.cadenceSelected = this.cadenceSelected.bind(this);
    }

    speedSelected(e){
        this.setState({
            selectedQuantity: "speed"
        });
        this.forceUpdate()
    }

    hrSelected(e){
        this.setState({
            selectedQuantity: "heartrate"
        });
        this.forceUpdate()
    }

    powerSelected(e){
        this.setState({
            selectedQuantity: "power"
        });
        this.forceUpdate()
    }

    cadenceSelected(e){
            this.setState({
                selectedQuantity: "cadence"
            });
            this.forceUpdate()
    }

    render() {
        self = this;
        return (
            <div margin-bottom="60px">
                <canvas id="myChart" width="400" height="150"/>
                <div className="row" style={{'marginBottom':'20px'}}>
                    <div className="col-md-2" />
                    <div className="col-md-8">
                        <table width="100%">
                            <td>
                                <Button style={{opacity: (this.state.selectedQuantity === "speed") ? "1.0" : "0.5"}} id="speedButton" className="chart-button speed-button" onMouseDown={() => this.speedSelected()}>speed</Button>
                            </td>
                            <td>
                                <Button style={{opacity: (this.state.selectedQuantity === "heartrate") ? "1.0" : "0.5"}} id="heartrateButton" className="chart-button heartrate-button" onMouseDown={() => this.hrSelected()}>heartrate</Button>
                            </td>
                            <td>
                                <Button style={{opacity: (this.state.selectedQuantity === "power") ? "1.0" : "0.5"}} id="powerButton" className="chart-button power-button" onMouseDown={() => this.powerSelected()}>power</Button>
                            </td>
                            <td>
                                <Button style={{opacity: (this.state.selectedQuantity === "cadence") ? "1.0" : "0.5"}} id="cadenceButton" className="chart-button cadence-button" onMouseDown={() => this.cadenceSelected()}>cadence</Button>
                            </td>
                        </table>
                    </div>
                    <div className="col-md-2" />
                </div>
            </div>
        );
    }

    getButtonClassName(item, defaultClass) {
        if (this.state.buttonStates[item]) {
            return defaultClass + "-on";
        } else {
            return defaultClass + "-off";
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if (nextProps.activity !== this.props.activity || nextState.show !== this.state.show){
            return true;
        }
        else{
            return false;
        }
    }

    componentDidMount() {
        let usedSampleIndexes = [];
        let altitudeSet = [ChartEntity];
        let speedSet = [ChartEntity];
        let heartrateSet = [ChartEntity];
        let powerSet = [ChartEntity];
        let cadenceSet = [ChartEntity];
        let programSet = [ChartEntity];

        // če immamo opravka v programBasedTraining, pripravimo sezna setpointov
        let programSetpoints = null;        // zaporedoma razvrščeni setpointi
        if (this.props.activity.program !== undefined
            && this.props.activity.program !== null
            && this.props.activity.program.intervals !== undefined
            && this.props.activity.program.intervals !== null){
            programSetpoints = [];
            this.props.activity.program.intervals.forEach(interval => {
                for (let i = 0; i < interval.duration; i++){
                    programSetpoints.push(interval.setpoint * this.props.activity.userFtp ?? 300);
                }
            });
        }

        if (this.props.activity !== undefined && this.props.activity.samples !== undefined) {
            let samplesCount = this.props.activity.samples.length;
            var i = 0;
            var j = 0;
            this.props.activity.samples.forEach(sample => {
                if (i > samplesCount / SAMPLES_RESOLUTION) {
                    let xAxisVal = (this.props.activity.activityType === "programBasedTraining" || this.props.activity.activityType === "workout") ? j : sample.distance;
                    altitudeSet.push(new ChartEntity(xAxisVal, sample.altitude));
                    speedSet.push(new ChartEntity(xAxisVal, sample.speed * 3.6));
                    heartrateSet.push(new ChartEntity(xAxisVal,sample.heartRate));
                    cadenceSet.push(new ChartEntity(xAxisVal,sample.cadence));
                    powerSet.push(new ChartEntity(xAxisVal,sample.power));
                    if (this.props.activity.activityType === "programBasedTraining") {
                        let setpoint = programSetpoints[j];
                        programSet.push(new ChartEntity(xAxisVal, setpoint));
                    }
                    var date = new Date(sample.timestamp);
                    usedSampleIndexes.push(j);
                    i = 0;
                }
                i++;
                j++;
            });

            this.setState({
                altitudeSet: altitudeSet,
                speedSet: speedSet,
                heartrateSet: heartrateSet,
                cadenceSet: cadenceSet,
                powerSet: powerSet,
                programSet: programSet,
                usedSampleIndexes: usedSampleIndexes
            });
        }
        this.forceUpdate();
    }

    componentDidUpdate() {
        this.populateGraph()
    }

    segmentSelected(firstIndex, lastIndex){
        this.props.onSegmentSelected(firstIndex, lastIndex);
    }

    chartHover(index){
        this.props.onChartHover(index);
    }

    populateGraph() {
        var startDragging = false;
        var lastHoverIndex;
        var dragIntervalStart;
        var dragIntervalEnd;

        if (this.state.lastChart !== undefined) {
            this.state.lastChart.destroy();
        }

        var ctx = document.getElementById('myChart').getContext('2d');
        ctx.height = "200px";

        var speedColor = "rgba(15, 186, 248)";
        var speedFill = ctx.createLinearGradient(0, 0, 0, 400);
        speedFill.addColorStop(0, "rgba(15, 186, 248, 0.9)");
        speedFill.addColorStop(1, "rgba(15, 186, 248, 0)");

        var powerColor = 'rgba(52, 231, 228)';
        var powerFill = ctx.createLinearGradient(0, 0, 0, 400);
        powerFill.addColorStop(0, 'rgba(52, 231, 228, 0.9)');
        powerFill.addColorStop(1, 'rgba(52, 231, 228, 0.0)');

        var hrColor = "rgba(239, 87, 119)";
        var hrFill = ctx.createLinearGradient(0, 0, 0, 400);
        hrFill.addColorStop(0, "rgba(239, 87, 119, 0.9)");
        hrFill.addColorStop(1, "rgba(239, 87, 119, 0.0)");

        var cadenceColor = "rgba(255, 192, 72)";
        var cadenceFill = ctx.createLinearGradient(0, 0, 0, 400);
        cadenceFill.addColorStop(0, "rgba(255, 192, 72, 0.9)");
        cadenceFill.addColorStop(1, "rgba(255, 192, 72, 0.0)");

        let minAltitude = Math.min(...this.state.altitudeSet.map(x => x.y).filter(Number));
        let maxAltitude = Math.max(...this.state.altitudeSet.map(x => x.y).filter(Number));
        let maxSetpoint = Math.max(...this.state.programSet.map(x => x.y).filter(Number));

        var selectedDataset;
        var selectedColor;
        var selectedFill;
        switch (this.state.selectedQuantity){
            case "speed":
                selectedDataset = this.state.speedSet;
                selectedColor = speedColor;
                selectedFill = speedFill;
                break;
            case "heartrate":
                selectedDataset = this.state.heartrateSet;
                selectedColor = hrColor;
                selectedFill = hrFill;
                break;
            case "cadence":
                selectedDataset = this.state.cadenceSet;
                selectedColor = cadenceColor;
                selectedFill = cadenceFill;
                break;
            case "power":
                selectedDataset = this.state.powerSet;
                selectedColor = powerColor;
                selectedFill = powerFill;
                break;
        }

        let chart = new Chart(ctx,{
            type: "line",
            data: {
                datasets: [
                    {
                        display: true,
                        label: "altitude",
                        yAxisID: "altitude",
                        data: this.state.programSet === null ? this.state.altitudeSet : this.state.programSet,
                        pointRadius: 0,
                        pointHitRadius: 5,
                        backgroundColor: "rgba(72, 84, 96, 0.2)",
                        borderColor: "rgba(72, 84, 96, 0.8)",
                        borderWidth: 0.5
                    },
                    {
                        hide: true,
                        label: "data",
                        yAxisID: "data",
                        data: selectedDataset,
                        pointRadius: 0,
                        backgroundColor: selectedFill,
                        borderColor: selectedColor,
                        borderWidth: 1.0
                    }
                ]
            },
            /*plugins: [
                {
                    afterEvent: function (chart, event, options) {
                        if (event.type === "mousedown") {
                            dragIntervalStart = lastHoverIndex;
                            startDragging = true;
                        }
                        if (event.type === "mouseup") {
                            if (startDragging) {
                                dragIntervalEnd = lastHoverIndex;
                                startDragging = false;
                                this.segmentSelected(dragIntervalStart, dragIntervalEnd);
                            }
                        }
                    }
                }
            ],*/
            options: {
                events: ["mouseup", "mousedown", "click", "mousemove", "touchmove", "touchstart"],
                hover: {
                    intersect: false,
                    onHover: function (evt, item) {
                        if (item.length) {
                            lastHoverIndex = self.state.usedSampleIndexes[item[0]._index];
                            self.chartHover(self.state.usedSampleIndexes[item[0]._index]);
                            //console.log("item index: ", item[0]._index, evt.type);
                        }
                        //var a = 1;
                    }
                },
                interaction: {
                    mode: 'nearest'
                },
                maintainAspectRatio: true,
                spanGaps: false,
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [
                        {
                            id: "altitude",
                            ticks: {
                                fontColor: "black",
                                fontFamily: "Montserrat",
                                max:  this.state.programSet !== null ? undefined : (maxAltitude - minAltitude) < 200 ? Math.round((minAltitude + 200) / 100) * 100 : Math.round((maxAltitude + 100) / 100) * 100,
                                beginAtZero: this.state.programSet !== null
                            },
                            gridLines: {
                                display: false
                            },
                        },
                        {
                            display: true,
                            id: "data",
                            position: "right",
                            ticks: {
                                fontColor: "black",
                                fontFamily: "Montserrat",
                                display: true,
                                beginAtZero: true
                            },
                            /*ticks: {
                                max: Math.ceil((Math.max(...selectedDataset.map(x => x.y).filter(Number)) * 1.1)/10) * 10,
                                beginAtZero: true
                            },*/
                            gridLines: {
                                display: false
                            },
                        }
                    ],
                    xAxes: [
                        {
                            type: 'linear',
                            display: false,
                            ticks: {
                                max: this.state.altitudeSet[this.state.altitudeSet.length - 1].x
                            },
                        }
                    ]
                },
                plugins: {
                    zoom: {
                        pan: {
                            enabled: false,
                            mode: "xy"
                        },
                        zoom: {
                            drag: true,
                            enabled: false,
                            speed: 0.1,
                            mode: "x",
                            rangeMin: {
                                // Format of min zoom range depends on scale type
                                x: 100,
                                y: 100
                            }
                        }
                    }
                }
            }
        });

        this.setState({lastChart: chart});
    }
}

/* background colorsets:
backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)'
                ]
           borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)'
                ],*/

export default LineChart;