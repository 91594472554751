import React, {useState} from "react";
import powerCurveJson from './powerCurve.json';
import powerCurveRefJson from './powerCurveRef.json';
import Chart from 'chart.js';
import RingWidget from "../activity/RingWidget";
import LineWidget from "../activity/LineWidget";
import {secondsToTimestamp} from "../activity/Helper";

class ChartEntity {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
}

let powerCurve = JSON.parse(JSON.stringify(powerCurveJson))
let powerCurveRef = JSON.parse(JSON.stringify(powerCurveRefJson))
let zones = [12,63,315,682,412,127,322];

class AnalysisSection extends React.Component {
    constructor() {
        super();
        this.state = {
            relativePosition: 0.0
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
        this.populateChart()
    }

    listenToScroll = () => {
        {/*const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        this.setState({
            relativePosition: (window.scrollY / 800) % 1
        });
        this.populateChart()*/}
    }


    render() {
        let duration = zones.reduce(function(a, b){
            return a + b;
        });
        return(
            <div className="container" style={{maxWidth: "400px"}}>
                <p className="title-small light-color">POWER PROFILE</p>
                <canvas id={this.props.chartName} height="150px" style={{paddingLeft: "30px", paddingRight: "0px"}}/>
                <table width="100%" style={{marginTop: 24}}>
                    <tr>
                        <td>
                            <RingWidget darkMode={true} percentPerUnit={powerCurve.fiveSec/powerCurveRefJson.fiveSec} title="5s" value={powerCurve.fiveSec}/>
                        </td>
                        <td>
                            <RingWidget darkMode={true} percentPerUnit={powerCurve.oneMin/powerCurveRefJson.oneMin} title="1m" value={powerCurve.oneMin}/>
                        </td>
                        <td>
                            <RingWidget darkMode={true} percentPerUnit={powerCurve.fiveMin/powerCurveRefJson.fiveMin} title="5m" value={powerCurve.fiveMin}/>
                        </td>
                        <td>
                            <RingWidget darkMode={true} percentPerUnit={powerCurve.twentyMin/powerCurveRefJson.twentyMin} title="20m" value={powerCurve.twentyMin}/>
                        </td>
                    </tr>
                </table>
                <div style={{marginTop: "48px"}}>
                    <LineWidget title="Neurom." darkMode={true} value={secondsToTimestamp(zones[6])} ppu={zones[6] / duration} color="#EF5777"/>
                    <LineWidget title="Anaerobic" darkMode={true} value={secondsToTimestamp(zones[5])} ppu={zones[5] / duration} color="#FFA801"/>
                    <LineWidget title="VO2 max" darkMode={true} value={secondsToTimestamp(zones[4])} ppu={zones[4] / duration} color="#FFD32A"/>
                    <LineWidget title="Threshold" darkMode={true} value={secondsToTimestamp(zones[3])} ppu={zones[3] / duration} color="#0FBAF8"/>
                    <LineWidget title="Tempo" darkMode={true} value={secondsToTimestamp(zones[2])} ppu={zones[2] / duration} color="#4ACFF9"/>
                    <LineWidget title="Endurance" darkMode={true} value={secondsToTimestamp(zones[1])} ppu={zones[1] / duration} color="#00D7D5"/>
                    <LineWidget title="Recovery" darkMode={true} value={secondsToTimestamp(zones[0])} ppu={zones[0] / duration} color="#34E7E3"/>
                </div>
            </div>
        );
    }

    populateChart() {
        var ctx = document.getElementById(this.props.chartName).getContext('2d');
        ctx.height = "150px";

        let data1 = [];
        let data2 = [];

        for (let i = 0; i < powerCurve.powerCurvePoints.length; i++) {
            const powerCurvePoint = powerCurve.powerCurvePoints[i];
            data1.push(new ChartEntity(i, powerCurvePoint.value));
        }

        for (let i = 0; i < powerCurveRef.powerCurvePoints.length; i++) {
            const powerCurvePoint = powerCurveRef.powerCurvePoints[i];
            data2.push(new ChartEntity(i, powerCurvePoint.value));
        }

        let chart = new Chart(ctx, {
            type: 'line',
            data: {
                datasets: [{
                    label: 'First dataset',
                    data: data1,
                    tension: 0.4,
                    backgroundColor: "rgba(15, 186, 248, 0.7)",
                    fill: true,
                    pointRadius: 0,
                    borderColor: "#0FBAF8",
                    borderWidth: 1.0
                },
                    {
                        label: 'First dataset',
                        data: data2,
                        tension: 0.4,
                        backgroundColor: "#1E272E",
                        fill: true,
                        pointRadius: 0,
                        borderColor: "#1E272E",
                        borderWidth: 1.0
                    }]
            },
            options: {
                maintainAspectRatio: true,
                spanGaps: false,
                legend: {
                    display: false
                },
                animation: false,
                scales: {
                    yAxes: [
                        {
                            max: 1500,
                            display: false,
                            ticks: {
                                max: 1300,
                                beginAtZero: true
                            }
                        },
                        {
                            max: 1500,
                            display: false,
                            ticks: {
                                max: 1300,
                                beginAtZero: true
                            }
                        }
                    ],
                    xAxes: [
                        {
                            type: 'linear',
                            display: false
                        }
                    ]
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    deferred: {
                        xOffset: 150,   // defer until 150px of the canvas width are inside the viewport
                        yOffset: '50%', // defer until 50% of the canvas height are inside the viewport
                        delay: 500      // delay of 500 ms after the canvas is considered inside the viewport
                    }
                }
            }
        });
    }
}

export default AnalysisSection;